<template>
  <div class="sectionmodal-overlay overflow-hidden z-40 fixed">
    <div class="sectionmodal absolute top-0 left-0 z-50 w-full max-h-full m-0">
      <div class="overflow-hidden w-full">
        <div class="sectionmodal-content absolute top-0 left-0 w-full flex">
          <div class="sectionmodal-content-inner relative w-full m-0" ref="content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ScrollManager from "../webgl/utils/ScrollManager";

export default {
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("close");
    }
  },

  mounted: function() {
    document.addEventListener("keydown", (e) => {
      if (this.show && e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          document.body.classList.add("sectionmodal-open");
          ScrollManager.lock()
        } else {
          document.body.classList.remove("sectionmodal-open");
          ScrollManager.unlock()
        }
      }
    }
  },
};
</script>
<style lang="scss" scope>
.sectionmodal-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media(min-width: 1024px) {
    position: absolute;
  }
  section {
    overflow: hidden;
  }
}
.sectionmodal-content {
  background-color: #0f1426;
}

.sectionmodal,
.overflow-hidden,
.sectionmodal-content,
.sectionmodal-content-inner {
  height: 100%;
}
</style>
