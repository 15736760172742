<template>
  <section id="home" ref="container" data-section="0" class="relative m-0 p-0 w-full min-h-screen">
    <div ref="maskcontainer" class="mask-container absolute w-full h-full top-0 left-0 select-none">
      <div ref="imgdesktop" alt="" class="img-desktop">
        <VideoBackground :videoData="sectionData.video" class="cover-darken"></VideoBackground>
      </div>
    </div>
    <div class="relative w-full min-h-screen flex m-auto content-center">
      <div class="content m-auto text-center">
        <h1 class="text-6xl">
          <img :src="sectionData.titleimg" :alt="sectionData.title" width="auto" height="auto" class="select-none" />
        </h1>
        <div class="next-cta absolute bottom-24 mx-auto text-center" v-on:click="nextSection">
          <ArrowScrollDown class="m-auto"></ArrowScrollDown>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WebGL from "@/webgl/WebGL.js";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import SectionAnimator from "@/animation/SectionAnimator";

import VideoBackground from "@/components/VideoBackground.vue";
import ArrowScrollDown from "@/components/ArrowScrollDown.vue";

export default {
  components: { VideoBackground, ArrowScrollDown },
  data() {
    return {
      sectionData: window.locale["home"],
    };
  },
  methods: {
    nextSection: () => {
      ScrollManager.scrollTo(null, 1);
    },
  },
  mounted() {
    ScrollManager.addObserver(this.$refs.container);
    SectionAnimator.add(0, this.$refs.maskcontainer, this.$refs.imgdesktop, this.$refs.imgmobile);
  },
  created() {},
};
</script>
<style lang="scss" scoped>
#home {
  .content {
    h1 {
      img {
        width: calc(900 / 1440 * 100vw);
        max-width: 653px;

        @media (min-width: 768px) {
          width: calc(653 / 1440 * 100vw);
        }
      }
    }
  }

  .webgl-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
    // opacity: 0.5;
  }
  .next-cta {
    left: 50%;
    transform: translateX(-50%);
  }
  .arrow-down {
    transform: scale(0.65);
  }
}
</style>
