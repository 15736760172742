import gsap from "gsap/gsap-core";
import { PlaneBufferGeometry, Mesh, RawShaderMaterial, LinearFilter, CanvasTexture, Color, Vector2, ShaderMaterial } from "three";
import ScrollManager from "../utils/ScrollManager";
import RAF from '../utils/raf'

var fragmentShader = `
precision highp float;

varying vec2 vUv;

uniform sampler2D uTexture;
uniform float uTime;
uniform float uAlpha;
uniform vec3 uBgColor;
uniform vec2 uDimensions;

#define PI 3.1415926535

vec4 layer(vec4 foreground, vec4 background) {
    return foreground * foreground.a + background * (1.0 - foreground.a);
}


void main() {
    vec2 uv = vUv;
    float time = uTime; 

    uv = (uv - vec2(0.5)) * 2.8 + vec2(0.5); 
    uv.x = (uv.x - 0.5) * (uDimensions.x/uDimensions.y) + 0.5; 
    float w = sin((uv.x + uv.y - time + sin(2.5 * uv.x + 4.5 * uv.y) * PI * .3) * PI * .4);
    
    uv *= 1. + (.036 - .036 * w);

    vec4 bg = vec4(uBgColor, 1.0);
    vec4 t = texture2D(uTexture, uv);

    t.rgb /= uAlpha;

    vec4 f = layer(t, bg);

    f.rgb += w * .15 * uAlpha;

    gl_FragColor = f;
    gl_FragColor.a *= uAlpha;
    // gl_FragColor.rgb /= gl_FragColor.a;
}
`

var vertexShader = `
precision highp float;

varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

export default class LoaderGL {
    constructor(webgl) {
        this.loader = { toGo: 0 }
        this.webgl = webgl
        this.wordList = ["Bienvenue", "Bienvenido", "Welcome", "어서 오십시오", "Bem-vindo"]
        this.wordIndex = 0
        this.word = this.wordList[this.wordIndex]
        this.callbackOnDoneLoading = []
        this.rafId = null

        this.createCanvas()
        this.initMesh()
        this.setPosition()
        this.addEvents()

        this.startNameLoop();

        this.webgl.scene.add(this.mesh)
    }

    initMesh() {
        this.geometry = new PlaneBufferGeometry(1,1,1,1);
        this.material = new ShaderMaterial({
            fragmentShader,
            vertexShader,
            transparent: true,
            uniforms: {
                uTexture: { value: new CanvasTexture(this.canvas) },
                uBgColor: { value: new Color('#BD0110') },
                uDimensions: { value: new Vector2() },
                uTime: { value: 100 },
                uAlpha: { value: 1 }
            }
        });

        // this.material.uniforms.uTexture.value.magFilter = LinearFilter
        this.material.uniforms.uTexture.value.minFilter = LinearFilter

        this.mesh = new Mesh(this.geometry, this.material);
    }

    loadImg() {
        // const loader = new TextureLoader();
        // loader.load('/images/temp_test.jpg', (texture ) => { 
        //     this.material.uniforms.uTexture.value  = texture
        //     this.material.uniforms.uTexture.value.needsUpdate = true
        //     this.material.uniforms.needsUpdate = true
        // })
    }

    setPosition() {
        let width = window.innerWidth
        let height = window.innerHeight

        this.mesh.scale.x = width;
        this.mesh.scale.y = height;

        this.mesh.position.x = width * 0.5
        this.mesh.position.y = height * -0.5

        this.mesh.position.z = 4

        this.material.uniforms.uDimensions.value.x = width
        this.material.uniforms.uDimensions.value.y = height
        // this.material.
    }

    load() {
        if (document.fonts.ready) {
            document.fonts.ready.then(() => {
                this.startLoader();
            });
        } else {
            setTimeout(() => {
                this.startLoader();
            }, 500);
        }
    }

    startLoader() {
        this.rafId = RAF.add(this.updateCanvas.bind(this))

        gsap.to(this.loader, {
            toGo: 100,
            duration: Math.max(3, Math.random() * 6),
            ease: 'Cubic.easeOut',
            onComplete: () => {
                this.callbackOnDoneLoading.map(callback => callback ? callback() : null)
            },
            delay: 0.2
        })
    }

    createCanvas() {
        this.canvas = document.createElement('canvas');
        this.canvas.width = 500;
        this.canvas.height = 500;

        this.ctx = this.canvas.getContext('2d');

    //    this.drawCanvas(0)

        // document.body.appendChild(this.canvas)
        // this.canvas.style.zIndex = '99999'
        // this.canvas.style.position = 'absolute'
        // this.canvas.style.top = '0px'
        // this.canvas.style.left = '0px'
        // this.canvas.style.border = '1px solid white'
    }

    drawCanvas(numb) {
        this.ctx.clearRect(0, 0, 500, 500)
        this.ctx.font = '80px Ogg'
        this.ctx.textAlign = 'center'
        this.ctx.fillStyle = '#ffffff'

        this.ctx.fillText(this.word, 250, 250)
        
        this.ctx.font = '40px Ogg'
        this.ctx.fillText(`${Math.round(numb)}%`, 250, 350)
    }

    startNameLoop() {
        this.nameLoop = setInterval(() => {
            this.wordIndex++
            this.wordIndex = this.wordIndex % this.wordList.length;
            this.word = this.wordList[this.wordIndex]
        }, 500);
    }

    updateCanvas() {
        // Ogg
        this.drawCanvas(this.loader.toGo)
        this.material.uniforms.uTexture.value.needsUpdate = true
    }

    addEvents() {
        window.addEventListener('resize', this.setPosition.bind(this))
    }

    onDoneLoading(callback) {
      this.callbackOnDoneLoading.push(callback)
    }

    animateOut() {
        // gsap.to(this.material.uniforms.uAlpha, {
        //     value: 0,
        //     duration: 1,
        //     ease: 'Cubic.easeInOut',
        //     delay: 0,
        //     onComplete: () => {
        //         clearInterval(this.nameLoop)
        //         this.webgl.animateInSection(0)
        //         this.mesh.visible = false;
        //         this.webgl.scene.remove(this.mesh)
        //     }
        // })
        // ScrollManager.isLoading = false;

        RAF.remove(this.rafId)
        this.rafId = null
        ScrollManager.isLoading = false;
        ScrollManager.canScroll = true;
        clearInterval(this.nameLoop)
        this.webgl.animateInSection(0)
        this.mesh.visible = false;
        this.webgl.scene.remove(this.mesh)
    }

    update() {
        if (this.mesh.visible) {
            this.mesh.position.y = window.innerHeight * -0.5 + ScrollManager.scroll
            this.material.uniforms.uTime.value += 0.01;
        }
    }
}