<template>
  <section id="containerExplorer" :class="loadingClass" class="absolute top-0 left-0 w-full min-h-screen overflow-hidden">
    <div v-if="isLoading" class="flex h-screen w-full flex-wrap justify-center items-center">
        <span class="loading-text mr-4 text-xl">Loading</span>
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#F28F38" stroke-width="4"></circle>
          <path class="opacity-75" fill="#F28F38" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>
    <canvas class="webgl"></canvas>
    <div class="back-arrow absolute top-0 left-0 m-0 text-left h-full w-full z-50">
      <div class="md:pt-48 pt-0">
        <router-link to="/buccaneer-bay/access" class="relative flex m-auto pointer-events-auto py-2">
          <ArrowScrollDown class="transform rotate-90"></ArrowScrollDown>
        </router-link>
      </div>
      <div class="container-search absolute bottom-2 left-12 sm:bottom-10 sm:left-48 text-seriftext-left">
        <p v-if="sectionData.inputprompt" v-html="sectionData.inputprompt" class="pb-4 pr-3 text-left"></p>
        <div v-if="!containerInputIsValid" class="text-red-500 text-sm">IDs are numeric and must be 4 characters</div>
        <form @submit.prevent="handleContainerSubmit()">
          <span class="input-border relative inline-block py-4 text-center"
            ><input type="text" @input.stop="handleContainerIdInput" :value="submittedContainerID" :placeholder="sectionData.inputcta"
          /></span>
          <span class="relative top-4">
            <a @click.prevent="handleContainerSubmit()"><ArrowScrollDown class="transform -rotate-90 scale-75"></ArrowScrollDown></a>
          </span>
        </form>
      </div>
      <transition name="fade">
        <div v-if="containerData" class="absolute top-0 right-0 w-full rounded container-info p-6">
          <div class="relative lg:w-10/12 xl:w-8/12 mx-auto">
            <div class="flex w-full flex-wrap items-center">
              <img class="select-none mx-auto" width="120" height="auto" src="/images/buccaneer/containerNumBG.svg" alt="" />
              <div class="absolute top-11 left-0 text-center right-0 mx-auto">
                {{ containerData.id }}
              </div>
              <div class="absolute top-11 right-0">
                <CloseButton theme="dark" @close="handleCloseInfoPanel()"/>
              </div>
            </div>
            <div class="grid grid-cols-2 w-full">
              <div class="container-info-left text-right">
                <div class="w-full pr-4">
                  <div class="w-full mb-2">{{containerData.title}}</div>
                  <div class="w-full text-sm mb-1">{{containerData.casenumber}}</div>
                  <div class="w-full flex flex-wrap justify-end my-2">
                    <div class="m-2 cursor-pointer" @click.prevent="openModal(media.source, media.type)" v-for="media in containerData.data" :key="media.thumb">
                      <img :src="media.thumb" />
                    </div>
                  </div>
                  <div class="w-full text-sm mb-1">{{containerData.info}}</div>
                </div>
              </div>
              <div class="text-left container-info-right">
                <div class="w-full pl-4">
                  <div class="w-full text-sm my-1" v-html="containerData.specs"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-data relative flex space-x-64">
            <div class="description text-right text-sans"></div>
            <div class="specs text-left text-sans"></div>
          </div>
        </div>
      </transition>
      <HotspotModal v-if="showModal && modalData && modalData.mediaSource" @close="showModal = false">
        <video class="mb-4 max-w-sm sm:max-w-2xl" controls :src="modalData.mediaSource" v-if="modalData.mediaType === 'video'"></video>
        <div v-if="modalData.mediaType === 'image'">
          <div v-if="Array.isArray(modalData.mediaSource)">
            <img class="max-w-sm sm:max-w-2xl mb-4" :key="imageSource" v-for="imageSource in modalData.mediaSource" :src="imageSource" />
          </div>
          <div v-else>
            <img class="max-w-sm sm:max-w-2xl mb-4" :src="modalData.mediaSource" />
          </div>
        </div>
      </HotspotModal>
    </div>
  </section>
</template>

<script>
import ArrowScrollDown from "@/components/ArrowScrollDown.vue";
import HotspotModal from "@/components/HotspotModal.vue";
import CloseButton from '@/components/CloseButton.vue';
export default {
  components: { ArrowScrollDown, HotspotModal, CloseButton },
  props: {
  backDestination: {
      type: String,
      default: "",
    },
  },
  computed: {
    loadingClass: function() {
      return this.isLoading ? 'container-loader' : '';
    }
  },
  data() {
    return {
      cControl: null,
      accessCode: "",
      submittedContainerID: "",
      modalData: "",
      containerInputIsValid: true,
      sectionData: window.locale["container"],
      containerData: null,
      showModal: false,
      isLoading: false,
    };
  },
  methods: {
    handleContainerIdInput: function(e) {
      const val = e.target.value;
      if(this.containerData) {
        // user is typing again and we can remove the info panel
        this.containerData = null;
      }
      if (isNaN(val) || val.length > 4) {
        this.containerInputIsValid = false;
      } else {
        this.containerInputIsValid = true;
        this.submittedContainerID = e.target.value;
      }
    },
    handleContainerSubmit: function() {
      if(this.submittedContainerID.length !== 4) {
        this.containerInputIsValid = false;
        return;
      }
      window.Container.updateContainer();
      //easter egg cargo data
      const cargodata = window.locale.container.cargodata;
      //generic cargo data
      const otherCargodata = window.locale.container.otherCargodata;
      let containerData = cargodata.find(data => data.id === this.submittedContainerID);
      if(this.submittedContainerID === '0000' || !containerData) {
        // if the user submits anything other than a secret number, we want to display a random
        // container, though not a secret container
        const randomData = otherCargodata[Math.floor(Math.random() * otherCargodata.length)];
        containerData = randomData;
      }
      this.containerData = {
        id: this.submittedContainerID,
        specs: containerData.specs,
        ...containerData.description,
      }
      //this.$router.push(this.accessDestination);
    },
    handleCloseInfoPanel: function() {
      this.submittedContainerID = '';
      this.containerData = null;
    },
    openModal: function(mediaSource, mediaType) {
      this.showModal = true;
      this.modalData = {
        mediaSource,
        mediaType
      }
    },
  },
  mounted() {
    this.cControl = document.createElement("script");
    this.cControl.setAttribute("src", "/container/bundle.4e443d78cdd77f96bc6e.js");
    document.head.appendChild(this.cControl);
    this.isLoading = true;

    window.setTimeout(() => { 
        if (window.Container !== null)
        {
          window.Container.mount()
          this.isLoading = false;
        }

     }, 3000);

  },
  destroyed() {
    window.Container.unmount()
    this.cControl.parentNode.removeChild(this.cControl);
  },
  created() {},
};
</script>
<style lang="scss">
.webgl {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}

#containerExplorer {

  &.container-loader {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
  }

  @media (max-width: 1024px) {
    position: fixed !important;
  }

  .back-arrow {
    padding: 12.22vh 2.5vw 1.67vh;
  }
  .easter-egg-img {
    max-width: 300px;
    height: auto;
  }
  .loading-text {
    color: #F28F38;
  }
  .container-info {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    color: #F28F38;
    min-height: 334px;
    .container-info-number {
      width: 200px;
    }
    .container-info-left {
      border-right: 2px solid #F28F38;
    }

    .description, .specs {
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      font-variant: small-caps;
      color: rgba(242, 143, 56, 0.84);
    }
  }
  .number {
    overflow: visible;
    &:after {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 262px;
      background: transparent url("/images/buccaneer/containerArrow.svg") top center no-repeat;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
