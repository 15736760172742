<template>
  <div id="assistant" class="m-0">
    <a
      v-if="!showAssistant"
      @click="
        {
          showAssistant = !showAssistant;
        }
      "
      class="assistant-cta cursor-pointer inline-flex pt-3 px-6"
      >{{ sectionData.buttonlabel }}</a
    >
    <div v-if="showAssistant">
      <VirtualAssistantChat></VirtualAssistantChat>
    </div>
  </div>
</template>
<script>
import VirtualAssistantChat from '@/components/VirtualAssistantChat.vue';
import { gsap, Expo } from 'gsap';

export default {
  components: { VirtualAssistantChat },
  props: {
    sectionData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showAssistant: false,
    };
  },
  mounted() {},
  methods: {
    toggle: function(el) {},
  },
};
</script>
<style lang="scss" scoped>
.assistant-cta {
  white-space: nowrap;
  height: 59px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.2);
  color: $textsecondary;
  border-radius: 50px;

  @media screen and (hover: hover) {
    transition: all 0.45s ease-in-out;
    &:hover {
      color: #0f1426;
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
