<template>
  <router-link class="m-auto block cursor-pointer" :to="destination">
    <span
      @mouseover="onmouseover"
      @mouseleave="onmouseleave"
      ref="navpoint"
      class="block nav-point"
      :style="`background-position:-${ssCoords[0]}px -${ssCoords[1]}px;`"
    ></span>
  </router-link>
</template>
<script>
import { TweenMax, gsap, Linear } from "gsap";
export default {
  props: {
    destination: {
      type: String,
      default: "/",
    },
  },
  data() {
    return {
      ssCoords: [0, 0],
      ssCoordsIndex: 0,
      ssProp: { val: 0 },
      rolloverFrames: [
        [0, 0],
        [84, 0],
        [168, 0],
        [252, 0],
        [336, 0],
        [420, 0],
        [0, 80],
        [84, 80],
        [168, 80],
        [252, 80],
        [336, 80],
        [420, 80],
        [0, 160],
      ],

      tl: null,
    };
  },
  mounted() {},
  methods: {
    updateSprite: function() {
      if (this.ssProp.val < this.rolloverFrames.length) {
        this.ssCoordsIndex = Math.floor(this.ssProp.val);
        this.ssCoords = this.rolloverFrames[this.ssCoordsIndex];
      }
    },

    onUpdate: () => {},
    onmouseover: function(el) {
      gsap.killTweensOf(this.ssProp);
      TweenMax.to(this.ssProp, {
        val: this.rolloverFrames.length - 1,
        duration: 0.3,
        ease: Linear.none,
        onUpdate: this.updateSprite,
      });
    },
    onmouseleave: function(el) {
      gsap.killTweensOf(this.ssProp);
      TweenMax.to(this.ssProp, {
        val: 0,
        duration: 0.3,
        ease: Linear.none,
        onUpdate: this.updateSprite,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-point {
  width: 82px;
  height: 78px;
  background: url("../assets/images/rollover.png") no-repeat;
  transform-origin: center center;
  transform: scale(0.35, 0.35);
  background-position: 0px 0px;
  background-size: auto 256px;
}
a {
  left: -5px;
  line-height: 0;
  //   background: rgba(255, 0, 0, 0.54);
  // transition: opacity 0.25s ease-in-out 0.5s;
  &.router-link-exact-active {
    .nav-point{
      background: transparent;
    }
  }
}
</style>
