import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import panZoom from "vue-panzoom";
import VueHorizontal from "vue-horizontal";
import "./plugins/utils";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(panZoom);
Vue.use(VueHorizontal);

//locale

const langData = { 
  en: require('@/locales/en.json'),
  // fr: require("@/locales/fr.json"),
  es: require('@/locales/es.json'),
  mx: require('@/locales/es.json'),
  co: require('@/locales/es.json'),
  ar: require('@/locales/es.json'),
  pe: require('@/locales/es.json'),
  ve: require('@/locales/es.json'),
  cl: require('@/locales/es.json'),
  ec: require('@/locales/es.json'),
  gt: require('@/locales/es.json'),
  cu: require('@/locales/es.json'),
  bo: require('@/locales/es.json'),
  do: require('@/locales/es.json'),
  hn: require('@/locales/es.json'),
  py: require('@/locales/es.json'),
  sv: require('@/locales/es.json'),
  ni: require('@/locales/es.json'),
  cr: require('@/locales/es.json'),
  pa: require('@/locales/es.json'),
  uy: require('@/locales/es.json'),
  gq: require('@/locales/es.json'),
  pr: require('@/locales/es.json'),

  id: require('@/locales/id.json'),
  // de: require("@/locales/de.json"),
  // it: require("@/locales/it.json"),
  // pt: require("@/locales/pt.json"),
};
window.locale = {};
new Vue({
  i18n,
  router,
  render: (h) => h(App),
  watch: {
    $route: {
      handler: function(to, from) {
        var parameters = this.$route.query;
        window.locale =
          parameters.code && langData[parameters.code]
            ? langData[parameters.code]
            : this.$i18n.locale && langData[this.$i18n.locale]
            ? langData[this.$i18n.locale]
            : langData.en;
        // console.log(window.locale);
      },
      deep: true,
      immediate: true,
    },
  },
}).$mount("#app");
