import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Vector2,
    OrthographicCamera,
    RGBAFormat,
    WebGLRenderTarget
} from 'three'

// import { gsap } from "gsap";
import RAF from './utils/raf'
import Mouse from './utils/Mouse'
import TitleGL from './components/TitleGL'

import ScrollManager from './utils/ScrollManager';
import SectionAnimator from '@/animation/SectionAnimator'
import LoaderGL from './components/LoaderGL';

class WebGL {
    constructor() {
        this.current = '';
        this.scroll = 0
        this.toRender = [];
        this.init()
        this.initLoader()
        // this.initPostProcessing()
        this.addEvents();
    }

    init() {
        this.scene = new Scene()
        this.scene.position.y = window.innerHeight * .5
        this.scene.position.x = -window.innerWidth * .5

        this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100)
        this.camera = new OrthographicCamera(
            window.innerWidth / -2,
            window.innerWidth / 2,
            window.innerHeight / 2,
            window.innerHeight / -2,
            0.1,
            10
        )

        this.camera.position.z = 5

        this.renderer = new WebGLRenderer({
            antialias: window.devicePixelRatio < 2,
            alpha: true
        })

        this.renderer.setSize(window.innerWidth, window.innerHeight)

        this.useAlphaRenderer(true)

        this.domElement = this.renderer.domElement
        // document.body.appendChild(this.renderer.domElement)
        RAF.add(this.render.bind(this))
    }

    initLoader() {
        this.loader = new LoaderGL(this)
    }

    initPostProcessing() {
            // const renderScene = new RenderPass(this.scene, this.camera );
            // const bloomPass = new UnrealBloomPass( new Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
            // // bloomPass.threshold = params.bloomThreshold;
            // // bloomPass.strength = params.bloomStrength;
            // // bloomPass.radius = params.bloomRadius;
            // // var width = window.innerWidth || 1;
            // // var height = window.innerHeight || 1;
            // var parameters = { format: RGBAFormat };
            // this.ppRt = new WebGLRenderTarget( window.innerWidth, window.innerHeight, parameters );
            // this.composer = new EffectComposer(this.renderer, this.ppRt);
            // this.composer.addPass(renderScene);
            // this.composer.addPass(bloomPass);
    }

    addEvents() {
        window.addEventListener('resize', this.resize.bind(this))
    }

    addTitle(params) {
        this.current = 'homepage';

        var title = new TitleGL(params);
        this.scene.add(title);

        this.toRender.push(title);
    }

    useAlphaRenderer(use = true) {
        this.renderer.setClearAlpha(0.0)
    }

    render() {
        this.renderer.render(this.scene, this.camera)
        // this.composer.render();
        for (let i = 0; i < this.toRender.length; i++) {
            this.toRender[i].update()
        }

        if (this.loader) this.loader.update()

        Mouse.update()
        ScrollManager.update()
        SectionAnimator.update()

        // this.toGo = -window.pageYOffset
        // this.toGo 
        // this.app.style.transform = `translate3D(0, ${this.scroll}px, 0)`
        // this.scroll += (this.toGo - this.scroll) * this.ease;
        // this.scene.position.y = -this.scroll + window.innerHeight * .5
        // this.scroll = this.toGo
    }

    resize() {
        // this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.left =  window.innerWidth / -2,
        this.camera.right = window.innerWidth / 2,
        this.camera.top = window.innerHeight / 2,
        this.camera.bottom = window.innerHeight / -2
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(window.innerWidth, window.innerHeight)

        this.scene.position.x = -window.innerWidth * .5
    }

    enableSection(id) {
        this.toRender.forEach((element) => {
            // console.log('enable ', id, element.sectionId)
            if (element.sectionId === parseFloat(id)) element.enable()
        });
    }
    
    disableSection(id) {
        this.toRender.forEach((element) => {
            // console.log(element.sectionId, id)
            if (element.sectionId === parseFloat(id)) {
                element.disable()
            }
        });
    }

    animateInSection(id) {
        this.toRender.forEach((element) => {
            if (element.sectionId === parseFloat(id)) {
                element.show()
            }
        });
    }

    animateOutSection(id) {
        this.toRender.forEach((element) => {
            if (element.sectionId === parseFloat(id)) {
                element.hide()
            }
        });
    }

    startLoading() {
        this.loader.load()
    }

    enable() {
        RAF.run();
    }

    disable() {
        RAF.stop();
    }

    destroy() {

    }
}

export default new WebGL()