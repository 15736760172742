import SectionAnimator from "@/animation/SectionAnimator";
import WebGL from "@/webgl/WebGL.js";
import { gsap } from 'gsap'
import { map } from './Math'

import { isMobile } from '@/webgl/utils/isMobile'
class ScrollManager {
    constructor() {
        this.scrollY = 0
        this.scroll = 0
        this.toGo = 0
        this.lockedScroll = 0

        this.isScrolling = false
        this.isLoading = true
        this.canScroll = true

        this.height = window.innerHeight
        this.width = window.innerWidth

        // this.webgl = webgl
        // this.app = document.querySelector('.app')

        this.ease = !isMobile ? 0.04 : 0.99
        this.direction = 0
        
        this.delta = 0.0
        this.deltaMax = window.innerHeight / 5
        this.velocity = [0, 0]

        this.initSectionObserver()
        this.initAnimationObserver()
        this.addEvents();
        // this.setObservers()
    }

    addEvents() {
        window.addEventListener('resize', this.resize.bind(this))
        window.addEventListener('wheel', (evt) => {
            if (this.isScrolling || this.isLoading) evt.preventDefault()
        }, { passive: false });
    }

    initSectionObserver() {
        this.sectionObserver = new IntersectionObserver(entries => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  // this.webgl.projects[entry.target.id].visible = true
                  // show
                    SectionAnimator.enableSection(entry.target.dataset.section)
                    WebGL.enableSection(entry.target.dataset.section)
                } else {
                    SectionAnimator.enableSection(entry.target.dataset.section)
                    WebGL.disableSection(entry.target.dataset.section)
                // hide / reset / disable
                // this.webgl.projects[entry.target.id].visible = false
            }
          })
        })
    }

    initAnimationObserver() {
        this.animationObserver = new IntersectionObserver(entries => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.animationObserver.unobserve(entry.target)
                SectionAnimator.animateInSection(entry.target.dataset.section)
                WebGL.animateInSection(entry.target.dataset.section)
            }
            //     else {
            //         SectionAnimator.animateOutSection(entry.target.dataset.section)
            //         WebGL.animateOutSection(entry.target.dataset.section)
            //         // hide / reset / disable
            //     // this.webgl.projects[entry.target.id].visible = false
            // }
          })
        }, { rootMargin: `0px 0px -${window.innerHeight * 0.3}px 0px` })
    }

    setObservers(sections) {
        // this.sectionObserver.observe(this.linkManager.links)
        sections.forEach(section => {
            this.sectionObserver.observe(section)
        })
    }

    addObserver(section) {
        // this.sectionObserver.observe(this.linkManager.links)
        this.sectionObserver.observe(section)
    }

    addAnimationObserver(target) {
        this.animationObserver.observe(target)
    }

    setHeight({ customHeight }) {

      if (isMobile) return 

      let height;

      if (customHeight != undefined) {
        height = customHeight
      } else {
        height = this.content.offsetHeight;
      }

      this.realHeight.style.height = `${height - this.height}px`
    }

    setApp(app, content, realHeight) {
        this.app = app;
        this.content = content
        this.realHeight = realHeight

        this.setHeight({})
    }

    resize() {
        this.width = window.innerWidth
        this.height = window.innerHeight
        
        this.deltaMax = window.innerHeight / 5

        if (isMobile) return false;

        let progress = map(this.scroll * -1, 0, this.height * 4, 0, 4, true)
        progress = Math.round(progress)
        this.app.scrollTo(0, window.innerHeight * progress)

        this.setHeight({})
    }

    scrollTo(target, sectionID) {
        this.isScrolling = true
        const scroll = {
            current: Math.abs(this.scroll)
        }

        let height = window.innerHeight * sectionID
        if (!target && sectionID) {
          target = document.querySelector(`section[data-section="${sectionID}"]`)
        }
        
        if (target) {
          height = scroll.current + target.getBoundingClientRect().top
        }
        
        if (!this.canScroll) {
          this.toGo -= target.getBoundingClientRect().top
          this.isScrolling = false
        } else {
          gsap.to(scroll, {
              current: height,
              duration: 0.7,
              ease: 'easeInOutCubic',
              onUpdate: ()  => { 
                this.app.scrollTo(0, scroll.current) 
              },
              onComplete: () => {
                  this.isScrolling = false
              }
          })
        }
    }

    update() {
        if (this.canScroll) {
          this.toGo = -this.app.scrollTop
        }

        if (this.content && !isMobile) this.content.style.transform = `translate3D(0, ${Math.round(this.scroll)}px, 0)`
        this.direction = this.toGo > this.scroll ? 1 : -1

        let diff = Math.round(Math.min((this.toGo - this.scroll) * -1, this.deltaMax))  / this.deltaMax
        
        WebGL.scene.position.y = -this.scroll + window.innerHeight * .5
        WebGL.scroll = this.scroll

        this.scroll += (this.toGo - this.scroll) * this.ease;
        
        this.delta += (diff - this.delta) * 0.05
        this.delta = Math.min(this.delta, 1.0);
        
        // this.sectionProgress = map(window.pageYOffset * -1, 0, window.innerHeight * 4, 0, 4, true)
        // console.log(Math.round(this.sectionProgress))
        // this.delta = (this.delta)
    }

    lock() {
      if (!isMobile) {
        const scroll = this.scroll
        this.lockedScroll = Math.abs(scroll)
        this.setHeight({customHeight: 0})
        this.canScroll = false
      }
    }

    unlock() {
      if (!isMobile) {
        this.setHeight({target: this.app})
        window.scrollTo(0, Math.abs(this.toGo))
        this.canScroll = true
      }
    }
}

export default new ScrollManager()
