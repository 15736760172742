<template>
  <div class="close absolute top-0 left-0 w-16 min-h-screen flex m-auto content-center">
    <div class="bg absolute top-0 left-0 w-full h-full"></div>
    <a @click.prevent="clickAction" class="block mx-auto md:my-auto mt-14 pointer-events-auto"
      ><ArrowDown class="transform rotate-90"></ArrowDown
    ></a>
  </div>
</template>
<script>
import { gsap } from "gsap";

import ArrowDown from "@/components/ArrowScrollDown.vue";
export default {
  components: { ArrowDown },
  props: {
    clickAction: {
      type: Function,
      default: function() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.close {
  width: calc(261 / 1440 * 100vw);
  min-width: 64px;
  @media (min-width: 768px) {
    width: 261px;
  }

  .bg {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.54) 52.05%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.1;
  }
}
</style>
