<template>
  <div class="hotspot">
    <a
      class="cursor-pointer block absolute top-0 left-0 z-50"
      :style="`top:${top};right:${right};bottom:${bottom};left:${left};width:${width}px;height:${height}px;`"
      @click="showModal = !showModal"
    >
    </a>
    <HotspotModal v-if="showModal" @close="showModal = false">
      <slot></slot>
    </HotspotModal>
  </div>
</template>
<script>
import HotspotModal from "@/components/HotspotModal.vue";

export default {
  components: { HotspotModal },
  props: {
    
    top: {
      type: String|Number,
      default: "inherit",
    },
    right: {
      type: String|Number,
      default: "inherit",
    },
    bottom: {
      type: String|Number,
      default: "inherit",
    },
    left: {
      type: String|Number,
      default: "inherit",
    },
    width: {
      type: String|Number,
      default: 100,
    },
    height: {
      type: String|Number,
      default: 100,
    },
  },
  data() {
    return {
      showModal: false,
      tl: null,
    };
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          document.body.classList.add("hsmodal-open");
        } else {
          document.body.classList.remove("hsmodal-open");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hotspot {
  a {
    background:rgba(100,0,0,.5);
    opacity: 0;
    // width: calc(251 / 1440 * 100vw);
    // height: calc(251 / 1440 * 100vw);
    // min-width: 150px;
    // min-height: 150px;
    // max-width: 251px;
    // max-height: 251px;
  }
}
</style>
