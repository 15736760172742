<template>
  <svg class="current-marker" width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="current-border"
      d="M5.823 18.2887L36.4999 0.57735L67.1768 18.2887V53.7113L36.4999 71.4226L5.823 53.7113V18.2887Z"
      stroke="white"
    />
    <path
      class="current-icon"
      d="M33.8649 36.165C33.8652 34.8441 34.1567 33.5395 34.7184 32.3446C35.2801 31.1497 36.0983 30.0939 37.1143 29.2527C38.1304 28.4115 39.3193 27.8057 40.5961 27.4786C41.8728 27.1515 43.2059 27.1112 44.5 27.3605C43.0204 26.496 41.3437 26.0279 39.6313 26.0012C37.9189 25.9745 36.2285 26.3902 34.7228 27.2081C33.2171 28.0261 31.9468 29.2188 31.0343 30.6715C30.1217 32.1241 29.5976 33.7878 29.5124 35.5024C29.4271 37.217 29.7837 38.9246 30.5477 40.461C31.3117 41.9974 32.4574 43.3108 33.8745 44.2747C35.2917 45.2387 36.9325 45.8207 38.6391 45.9647C40.3456 46.1088 42.0604 45.81 43.6184 45.0972C43.3521 45.1218 43.0843 45.1341 42.8149 45.1341C41.6396 45.1348 40.4756 44.9034 39.3895 44.4529C38.3035 44.0025 37.3166 43.3419 36.4854 42.5089C35.6542 41.6759 34.995 40.6869 34.5453 39.5984C34.0957 38.5099 33.8645 37.3432 33.8649 36.165Z"
      fill="white"
    />
  </svg>

</template>
<script>
import { gsap, Linear } from "gsap";

export default {
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (to === from) return;
      var tl = gsap.timeline({ paused: false });
      tl.to(".current-marker", { scale: 0.05, duration: 0.5, ease: Linear.none })
        .to(".current-icon", { opacity: 0, duration: 0.5, ease: Linear.none }, 0)
        .to(".current-marker", { scale: 1.0, duration: 0.5, ease: Linear.none })
        .to(".current-icon", { opacity: 1.0, duration: 0.5, ease: Linear.none }, "-=0.65");
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  transform-origin: center center;
  path {
    transform-origin: center center;
  }
}
</style>
