import { lerp, clamp, map } from '@/webgl/utils/Math'
import { isMobile } from '@/webgl/utils/isMobile'
import RAF from '@/webgl/utils/raf'

export default class GrabCarousel {
    constructor(el) {
        this.el = el
        this.el.classList.add('grab')

        this.slider = this.el.querySelector('.grabslider')
        this.ready = false
        
        this.visible = true
        
        this.init()
    }
    
    init() {
        this.bar = this.el.querySelector('.grabbar')
        this.progressBar = this.el.querySelector('.grabprogress')

        this.length = this.el.querySelectorAll('.grabitem').length

        this.progressBar.style.width = `${(1/this.length) * 100}%`

        this.startPosition = 0
        this.position = 0
        this.progress = 0
        this.easePosition = 0
        this.easeProgress = 0

        this.isGrabbing = false

        this.ready = true
        this.needsUpdate = false
        this.setSize()
        this.addEvents()

        // this.observer = new IntersectionObserver(entries => {
        //     entries.forEach((entry) => {
        //         if (entry.isIntersecting) {
        //             this.observer.unobserve(entry.target)
        //             this.show()
        //         }
        //     })
        // })

        // this.observer.observe(this.el)

        RAF.add(this.update.bind(this))
    }

    show() {
        // if (!this.el.dataset.noanim) {
        //     anime({
        //         targets: this.el,
        //         translateX: {
        //             value:  [1000, 1],
        //             easing: 'easeOutExpo',
        //             duration: 1500
        //         },
        //         opacity: {
        //             value:  [0, 1],
        //             easing: 'easeOutSine',
        //             duration: 700
        //         }
        //     })
        // }
    }

    setSize() {
        if (this.ready) {
            this.width = this.el.offsetWidth
            this.sliderWidth = this.slider.offsetWidth
            this.maxSlideWidth = this.sliderWidth - this.width
    
            this.progressWidth = this.bar.offsetWidth - this.progressBar.offsetWidth
        }
    }

    addEvents() {
        if (isMobile) {
            this.el.addEventListener('touchstart', this.touchDown.bind(this), { passive: true })
            this.el.addEventListener('touchmove', this.touchMove.bind(this), { passive: true })
            this.el.addEventListener('touchend', this.mouseUp.bind(this), { passive: true })
        } else {
            this.el.addEventListener('mousedown', this.mouseDown.bind(this))
            this.el.addEventListener('mousemove', this.mouseMove.bind(this))
            this.el.addEventListener('mouseup', this.mouseUp.bind(this))
            this.el.addEventListener('mouseleave', this.mouseLeave.bind(this))

            window.addEventListener('wheel', this.mouseWheel.bind(this), { passive: false });
        }

        window.addEventListener('resize', this.setSize.bind(this))
    }

    removeEvents() {
        this.el.removeEventListener('mousedown', this.mouseDown)
        this.el.removeEventListener('mousemove', this.mouseMove)
        this.el.removeEventListener('mouseup', this.mouseUp)
    }

    mouseDown(evt) {
        if (!this.isGrabbing) {
            this.isGrabbing = true
            this.el.classList.add('grabbing')
            evt.stopPropagation()
    
            this.startPosition = evt.clientX
        }
    }

    touchDown(evt) {
        if (!this.isGrabbing) {
            this.isGrabbing = true
            this.el.classList.add('grabbing')
            evt.stopPropagation()
    
            this.startPosition = evt.touches[0].clientX
        }
    }

    mouseMove(evt) {
        if (this.isGrabbing) {
            this.position -= (this.startPosition - evt.clientX) * 2
            this.position = clamp(this.position, -this.maxSlideWidth, 0)

            this.progress = Math.abs(this.position) / this.maxSlideWidth
            this.startPosition = evt.clientX
        }
    }

    touchMove(evt) {
        if (this.isGrabbing) {
            this.position -= (this.startPosition - evt.touches[0].clientX) * 2
            this.position = clamp(this.position, -this.maxSlideWidth, 0)

            this.progress = Math.abs(this.position) / this.maxSlideWidth
            this.startPosition = evt.touches[0].clientX
        }
    }

    mouseUp(evt) {
        if (this.isGrabbing) {
            this.isGrabbing = false
            this.el.classList.remove('grabbing')
        }
    }

    mouseLeave(evt) {
        if (this.isGrabbing) {
            this.isGrabbing = false
            this.el.classList.remove('grabbing')
        }
    }

    mouseWheel(evt) {
        if (this.visible) {
            if (evt.deltaX) {
                if (Math.abs(evt.deltaX) > Math.abs(evt.deltaY)) {
                    this.position -= evt.deltaX
                    this.position = clamp(this.position, -this.maxSlideWidth, 0)
                    this.progress = Math.abs(this.position) / this.maxSlideWidth
                }
                evt.preventDefault()
            }
        }
    }

    update() {
        if (this.ready && this.visible) {
            this.easePosition += (this.position - this.easePosition) * 0.07
            this.slider.style.transform = `translate3D(${this.easePosition}px, 0, 0)`
            
            this.easeProgress += (this.progress - this.easeProgress) * 0.07
            this.progressBar.style.transform = `translate3D(${this.progressWidth * this.easeProgress}px, 0, 0)`
        }
    }
}
