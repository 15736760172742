<template>
    <div class="webgl" :class="{ isContainerHided }">
        <button class="loader-enter-button input-border uppercase" :class="{ isButtonVisible }" @click="onClick">{{ btnWording }}</button>
        <div class="webgl-container" ref="webglcontainer"></div>
    </div>
</template>
<script>

import WebGL from "./../webgl/WebGL.js";

export default {
    data() {
      return {
        btnWording: window.locale.loader.btnWording,
        isContainerHided: false,
        isButtonVisible: false
      }
    },
    mounted() {
        let container = this.$refs.webglcontainer;
        container.appendChild(WebGL.domElement)

        WebGL.enable()
        WebGL.loader.onDoneLoading(() => this.onDoneLoading())
    },
    methods: {
        onDoneLoading() {
          this.isButtonVisible = true
          // this.onClick()
        },
        onClick() {
            this.$emit('clickButton')
            this.isContainerHided = true
            this.isButtonVisible = false
            setTimeout(() => WebGL.loader.animateOut(), 350)
        }
    }
}
</script>
<style lang="scss" scoped>
.webgl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999 !important;
    color: #fff;
    transition: opacity 350ms ease-in-out;

    &.isContainerHided {
      pointer-events: none;
      opacity: 0;
    }

    .webgl-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
      // opacity: 0.5;
    }
}

.loader-enter-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(17vh);
    top: 0;
    margin: auto;
    z-index: 5;
    margin: auto;
    cursor: pointer;
    height: 46px;
    width: 192px;
    border: 1px solid #fff;
    font-size: 18px;
    transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
    pointer-events: none;
    opacity: 0;
    transform-origin: center center;

    @media (max-width: 640px) {
      font-size: 1.2rem;
      width: 160px;
      height: 38px;
    }

    &:before,
    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 55.56px;
      height: 1.11px;
      background: #fff;
      transition: top 350ms ease-in-out, bottom 350ms ease-in-out;
    }

    &:before {
        top: -8px;
    }
    &:after {
        bottom: -8px;
    }

    &.isButtonVisible {
        opacity: 1;
        pointer-events: auto;
    }

    &:hover {
        transform: translateY(17vh) scale(0.95);

        &:before {
            top: -18px;
        }

        &:after {
            bottom: -18px;
        }
    }
}
</style>

