<template>
  <section id="brassMonkey" class="absolute top-0 left-0 m-0 p-0 w-full min-h-screen">
    <Saloon :sectionData="sectionData"></Saloon>
    <div
      v-if="inviteActive"
      ref="artauction"
      class="hidden art-auction absolute top-0 left-0 w-full min-h-screen flex m-0 content-center items-center"
    >
      <ClosePanel :clickAction="clickAction"></ClosePanel>
      <div
        ref="content"
        class="content relative left-16 min-h-screen h-full flex overflow-x-hidden overflow-y-auto my-auto text-left pl-24"
      >
        <div class="relative w-full pt-28 pl-16">
          <div class="m-auto flex-none">
            <h1 v-html="sectionData.brassmonkey.title" class="relative md:mb-40 mb-20 md:pt-0 pt-12"></h1>
            <h2 class="location pb-2" ref="h2" v-html="sectionData.brassmonkey.subtitle"></h2>
            <Divider ref="divider"></Divider>
            <p class="text-left" ref="text" v-html="sectionData.brassmonkey.content"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClosePanel from "@/components/ClosePanel.vue";
import MobileExplore from "@/components/MobileExplore.vue";
import Saloon from "@/components/Saloon.vue";

export default {
  components: { ClosePanel, MobileExplore, Saloon },
  props: {
    accessDestination: {
      type: String,
      default: "access",
    },
  backDestination: {
      type: String,
      default: "home",
    },
  },
  data() {
    return {
      accessCode: "",
      inviteActive: false,
      showModal: false,
      sectionData: window.locale["lowtown"],
    };
  },
  methods: {
    clickAction:function(){
      this.inviteActive = false;
    },
    enter(el, done) {
      this.showModal = true;
    },
    leave(el, done) {
      this.showModal = false;
    },
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          document.body.classList.add("sectionmodal-open");
        } else {
          document.body.classList.remove("sectionmodal-open");
        }
      }
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
#brassMonkey {
  h1 {
    opacity: 0;
  }
  .content {
    padding: 18.22vh 7.5vw 1.67vh;
  }
  .location {
    font-size: 30px;
  }
}
.art-auction {
  .content {
    background-color: #0f1426;
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));
    @media (min-width: 768px) {
      left: 261px;
      width: calc(100vw - 261px);
    }
  }
}
</style>
