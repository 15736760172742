/* eslint-disable */

class RAF {
    constructor() {
      if (!RAF.instance) {
        this._radId = null
        this._callbacksMap = {}
        this._callbacks = []
        RAF.instance = this
      }
      
      return RAF.instance
    }
    
    run () {
      this._radId = window.requestAnimationFrame(this.draw.bind(this))
    }
    
    draw () {
      this._callbacks.forEach(callback => {
        callback()
      })
      this._radId = window.requestAnimationFrame(this.draw.bind(this))
    }
    
    stop () {
      window.cancelAnimationFrame(this._radId)
      this._radId = null
    }
    
    add (callback) {
      if (typeof callback !== 'function') return false
      const index = Date.now()
      this._callbacksMap[index] = callback
      this._callbacks = Object.values(this._callbacksMap)
      
      if (this._radId) return index
      
      this.run()
      return index
    }
    
    remove (id) {
      if (!this._callbacksMap[id]) return
      delete this._callbacksMap[id]
      this._callbacks = Object.values(this._callbacksMap)
      if (this._callbacks.length === 0) {
        this.stop()
      }
    }
    
    destroy () {
      this.stop()
      this._callbacks = []
      this._callbacksMap = {}
    }
  }
  
  const instance = new RAF()
  
  export default instance