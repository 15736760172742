import { Vector2 } from 'three';
import { lerp, clamp, map } from './Math'

class Mouse {
    constructor() {
        this.mouseDir = [0, 0];
        this.mouseEasing = 0.04;
        this.mouseVector = new Vector2(0, 0)
        this.active = true

        this.setSize()
        this.addEvents()
    }

    setSize() {
        this.width = window.innerWidth
        this.height = window.innerHeight
    }

    resize() {
        this.width = window.innerWidth
        this.height = window.innerHeight
    }
    
    addEvents() {
        // if (!env.isMobile) {
        document.body.addEventListener('mouseenter', this.mouseOver.bind(this));
        document.body.addEventListener('mouseleave', this.mouseOut.bind(this));

        document.addEventListener('mousemove', evt => {
            this.mouseMove(evt);
        });


        window.addEventListener('resize', this.resize.bind(this))
        // } 
        // else {
        //     this.touchStartX = 0;
        //     this.touchEndX = 0;
        //     document.body.addEventListener('touchstart', this.touchStart.bind(this));
        //     document.body.addEventListener('touchend', this.touchEnd.bind(this));
    }

    mouseOver() {

    }

    mouseOut() {

    }
    

    mouseMove(evt) {
        this.mouseDir[0] = (evt.clientX  / this.width) * 2 - 1
        this.mouseDir[1] = ((evt.clientY / this.height) * 2 - 1) * -1
    }

    update() {
        this.mouseVector.x += (this.mouseDir[0] - this.mouseVector.x) * this.mouseEasing;
        this.mouseVector.y += (this.mouseDir[1] - this.mouseVector.y) * this.mouseEasing;
        // console.log(this.mouseVector[0], this.mouseVector[1])
    }

    enable() {

    }

    disable() {

    }
}

export default new Mouse()