<template>
  <a @click="openExplore" class="absolute top-2 right-2 inline-flex p-2">
    <svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="#000" fill-opacity="0.2" />
      <circle cx="22" cy="22" r="21.5" stroke="#fff" />
      <path
        d="M22.5841 15.6193C19.0849 15.6193 16.25 18.4751 16.25 22C16.25 25.5249 19.0849 28.3807 22.5841 28.3807C26.0833 28.3807 28.9182 25.5249 28.9182 22C28.9182 18.4751 26.0833 15.6193 22.5841 15.6193ZM20.5675 23.4141C20.7421 23.8383 20.9989 24.2246 21.3207 24.5523C21.6426 24.8765 22.026 25.1352 22.4472 25.3111C22.8683 25.487 23.3168 25.5767 23.7722 25.5767H23.7756C23.8818 25.5767 23.9845 25.5732 24.0872 25.5629L25.2924 25.449L24.1899 25.9561C23.6284 26.2147 23.0189 26.3493 22.4027 26.3493C22.2828 26.3493 22.163 26.3458 22.0432 26.3355C21.307 26.2734 20.5983 26.0216 19.9888 25.6008C19.376 25.1835 18.8829 24.6109 18.5543 23.9418C18.2564 23.3382 18.1023 22.676 18.1023 22.0035C18.1023 21.931 18.1057 21.8586 18.1091 21.7862C18.1468 21.0412 18.3728 20.3169 18.7665 19.6857C19.1603 19.0545 19.7081 18.5372 20.3586 18.1819C20.9886 17.837 21.6939 17.6576 22.4095 17.6576H22.478C23.2175 17.668 23.94 17.8715 24.5802 18.2474L25.6177 18.8579L24.4364 18.6268C24.2207 18.5854 24.0016 18.5647 23.7825 18.5647C23.4949 18.5647 23.2038 18.6027 22.9231 18.6751C22.4266 18.8027 21.9678 19.0407 21.5741 19.3684C21.1803 19.696 20.8619 20.1099 20.6462 20.5755C20.4271 21.0412 20.3141 21.5516 20.3141 22.069C20.3038 22.5312 20.3929 22.9864 20.5675 23.4141Z"
        fill="#fff"
      />
      <path
        d="M38.1248 21.8896C38.118 21.8827 36.4848 19.8892 33.7697 17.8922C31.058 15.8986 27.261 13.8947 22.9264 13.8947C18.5918 13.8947 14.627 15.8986 11.7441 17.8922C8.8612 19.8857 7.05683 21.8758 7.04998 21.8827L6.94727 21.9966L7.04656 22.1138C7.0534 22.1207 8.77218 24.1143 11.614 26.1078C14.4558 28.1014 18.424 30.1053 22.9264 30.1053C27.4287 30.1053 31.2292 28.1014 33.8998 26.1044C36.5704 24.1074 38.1214 22.1138 38.1283 22.1069L38.2139 21.9966L38.1248 21.8896ZM33.1876 26.1975C30.5889 28.0358 27.0521 29.7604 22.9264 29.7604C18.5267 29.7604 14.6201 27.7979 11.8126 25.825C10.4088 24.8386 9.28233 23.8521 8.50512 23.114C8.11822 22.745 7.81692 22.438 7.61492 22.2207C7.52932 22.1276 7.46084 22.0586 7.41291 22C7.86143 21.5275 9.74113 19.6133 12.4905 17.8025C15.2809 15.9642 18.965 14.2396 22.9264 14.2396C27.1514 14.2396 30.8868 16.2022 33.5677 18.1716C34.9064 19.158 35.9815 20.1444 36.7211 20.8825C37.0908 21.2516 37.375 21.5585 37.5702 21.7758C37.6558 21.8724 37.7208 21.9483 37.7722 22.0035C37.3887 22.4691 35.7487 24.3867 33.1876 26.1975Z"
        fill="#fff"
      />
    </svg>
  </a>
</template>
<script>
export default {
  methods: {
    openExplore: function() {
      this.$emit("explore");
    },
  },
};
</script>
<style lang="scss" scoped></style>
