<template>
  <section class="bid-details relative m-0 p-0 w-full min-h-screen">
    <!-- <transition v-on:enter="enter" v-on:leave="leave" :css="false" appear> -->
    <div class="relative w-full min-h-screen flex m-0 content-left items-end">
      <div class="content absolute top-0 left-0 m-0 text-left h-full md:flex block">
        <div class="md:pt-0 pt-12">
          <a @click.prevent="clickBack"><ArrowScrollDown class="transform rotate-90"></ArrowScrollDown></a>
        </div>
      </div>
    </div>
    <div ref="bidinfo" class="bid-info absolute top-0 left-0 min-h-screen">
      <div class="bg absolute w-full h-full top-0 left-0"></div>
      <div class="relative w-full h-full p-5 text-left">
        <h2 class="text-2xl pb-10 text-serif md:hidden block" v-html="bidpageData.title"></h2>
        <h1 class="pb-5" v-html="pageData.access.status"></h1>
        <p v-html="componentData.access.content"></p>
        <div class="access-cta absolute bottom-10 text-left">
          <p
            v-if="componentData.access.inputprompt"
            v-html="componentData.access.inputprompt"
            class="pb-4 pr-3 text-left"
          ></p>
          <div>
            <span class="input-border relative inline-block pt-1 pb-2 text-center"
              ><form @submit.prevent="accessCheck">
              >  <input type="text" v-model="accessCode" :placeholder="componentData.access.inputcta" 
              />
              ></form></span>
            <span class="relative top-4">
              <a @click.prevent="accessCheck"
                ><ArrowScrollDown class="transform -rotate-90 scale-75"></ArrowScrollDown
              ></a>
            </span>
          </div>
          <div class="location pt-10 text-serif md:hidden block"><h2 v-html="componentData.location"></h2></div>
        </div>
      </div>
    </div>
    <!-- </transition> -->
  </section>
</template>

<script>
import { gsap } from "gsap";
import ArrowScrollDown from "@/components/ArrowScrollDown.vue";

export default {
  props: {
    bidpageData: {
      type: Object,
      default: function() {},
    },
    componentData: {
      type: Object,
      default: function() {},
    },
    accessDestination: {
      type: String,
      default: "hightown/gallery",
    },
    bidInfo: {
      type: Object,
      default: function() {},
    },
    backDestination: {
      type: String,
      default: "home",
    },
  },
  components: { ArrowScrollDown },
  data() {
    return {
      accessCode: "",
      showModal: false,
    };
  },
  methods: {
    accessCheck: function() {
      if (this.accessCode == this.componentData.access.password) {
        console.log(this.accessDestination);
        this.$router.push(this.accessDestination);
      }
    },
    clickBack: function() {
      this.showModal = false;
      this.$router.push(this.backDestination);
    },
    beforeEnter(el) {
      // gsap.set(el, {
      //   opacity: 0.0,
      // });
    },
    enter(el, done) {
      this.showModal = true;
    },
    leave(el, done) {
      this.showModal = false;
    },
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          document.body.classList.add("sectionmodal-open");
        } else {
          document.body.classList.remove("sectionmodal-open");
        }
      }
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.bid-details {
  .content {
    padding: 12.22vh 2.5vw 1.67vh;

    @media (min-width: 768px) {
      padding: 18.22vh 7.5vw 1.67vh;
    }
  }
  .location {
    font-size: 30px;
  }

  .bid-info{
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));
    @media (min-width: 768px) {
      left: 261px;
      width: calc(100vw - 261px);
    }


    width: calc(100vw - 75px);
    @media (min-width: 768px) {
      width: calc(603 / 1440 * 100vw);
    }
    h1 {
      font-size: 49px;
      @media (min-width: 768px) {
        font-size: 64px;
      }

      line-height: 1em;
    }
    .bg {
      background:#fff;
    }

    .p-5 {
      padding: 12.89vh 5.2vw;

      @media (min-width: 768px) {
        padding: 15.89vh 9.3vw;
      }
    }
    .access-cta {
      p {
        font-weight: 300;
        font-size: 15px;
        line-height: 140%;
        max-width: 253px;
      }
      .input-border {
        &:before,
        &:after {
          content: " ";
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 55.56px;
          height: 1.11px;
          background: #fff;
        }
        &:before {
          top: -1px;
        }
        &:after {
          bottom: 0px;
        }
      }
      .top-4 {
        top: 15px;
      }
      input {
        max-width: 192px;
        position: relative;
        background: transparent;
        border: 1px solid #fff;
        font-size: 18px;
        line-height: 30px;
        margin: auto;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
