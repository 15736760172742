<template>
  <section id="brass-monkey-saloon" ref="container" data-section="4" class="relative m-0 p-0 w-full min-h-screen">
    <div ref="maskcontainer" class="mask-container absolute w-full h-full select-none">
      <div
        v-bind:style="{ backgroundImage: 'url(' + sectionData.image.landscape + ')' }"
        ref="imgdesktop"
        alt=""
        class="img-desktop cover-darken hidden md:block"
      ></div>
      <div
        v-bind:style="{ backgroundImage: 'url(' + sectionData.image.portrait + ')' }"
        ref="imgdesktop"
        alt=""
        class="img-desktop cover-darken md:hidden"
      ></div>
    </div>
    <div class="absolute top-0 left-0 w-full h-screen" ref="hotspots">
      <Hotspot :height="270" :left="'37vw'" :bottom="'50vh'" :width="200">
        <img class="wanted" src="/images/eastereggs/Karli_Wanted.jpg" alt="" />
      </Hotspot>
    </div>
    <div class="relative w-full min-h-screen flex m-0 content-center items-center">
      <div ref="content" class="content relative max-w-2xl mx-0 my-auto text-left pl-32">
        <h1 class="relative md:mb-40 mb-20 md:pt-0 pt-12" ref="h1" data-section="4">
          <span v-html="sectionData.title"></span>
          <span class="cta inline">
            <router-link to="/lowtown/brass-monkey-saloon" class="relative flex m-auto pointer-events-auto py-2">
              <ArrowDown class="transform -rotate-90 scale-75"></ArrowDown>
            </router-link>
          </span>
        </h1>
        <h2 class="location pb-2" v-html="sectionData.location"></h2>
        <Divider ref="divider"></Divider>
        <p class="text-left md:pr-0 pr-12 pb-12" ref="text" v-html="sectionData.content"></p>
      </div>
    </div>
    <transition :name="'modalfade'">
    <SectionModal v-show="saloonActive" :showModal="saloonActive" @close="saloonActive = false">
      <BrassMonkey />
    </SectionModal>
    </transition>
    <transition :name="'modalfade'">
      <SectionModal v-show="inviteActive" :showModal="inviteActive" @close="inviteActive = false">
        <Invite />
      </SectionModal>
    </transition>
  </section>

</template>

<script>
import BrassMonkey from "@/views/BrassMonkey.vue";
import Divider from "@/components/Divider.vue";
import ArrowDown from "@/components/ArrowScrollDown.vue";
import SectionModal from "@/components/SectionModal.vue";
import Invite from "@/views/Invite.vue";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import SectionAnimator from "@/animation/SectionAnimator";
import VideoBackground from "@/components/VideoBackground.vue";
import Hotspot from "@/components/Hotspot.vue";

export default {
  components: { ArrowDown, BrassMonkey, Divider, SectionModal, VideoBackground, Hotspot, Invite },
  data() {
    return {
      sectionData: window.locale["lowtown"],
      saloonActive: false,
      inviteActive: false
    };
  },
  methods: {},
  mounted() {
    SectionAnimator.add(4, this.$refs.maskcontainer, this.$refs.imgdesktop, this.$refs.imgmobile);
    ScrollManager.addObserver(this.$refs.container);

    SectionAnimator.addAnimation(4, this.$refs.h1, {
      content: this.$refs.content,
      divider: this.$refs.divider.$el,
      text: this.$refs.text,
      h2: this.$refs.h2,
    });
    ScrollManager.addAnimationObserver(this.$refs.h1);

  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (to === from) return;
        this.saloonActive = to.path == "/lowtown/brass-monkey-saloon";
        this.inviteActive = to.path == "/lowtown/invite";

        if (this.saloonActive) {
          this.$refs.hotspots.classList.add('pointer-events-none');
        } else {
          this.$refs.hotspots.classList.remove('pointer-events-none');
        }
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
<style lang="scss" scoped>
#brass-monkey-saloon {
  h1 {
    opacity: 0;
  } 
}
</style>
