import RAF from './../webgl/utils/raf'
import ScrollManager from './../webgl/utils/ScrollManager'
import { map, Easings } from '@/webgl/utils/Math'
import { gsap } from 'gsap'

export default class NavScroller {
    constructor(container) {
        this.container = container

        this.isGrabbing = false
        this.mouseIn = false

        this.current = -1

        this.height = 400
        this.progress = -1
        this.boxHeight = 72
        this.rotate = 0
        this.currentRotate = 0

        this.progressFloor = -1

        this.initBox()
        this.initPoints()
        this.initLines()


        this.addEvents()

        RAF.add(this.update.bind(this))
    }

    addEvents() {
        // resize
        // click
        this.box.addEventListener('mouseenter', () => {
            document.body.style.cursor = 'grab'
            this.mouseIn = true
        })
        this.box.addEventListener('mouseleave', () => {
            if (!this.isGrabbing) document.body.style.cursor = 'default'
            this.mouseIn = false
        })
        document.body.addEventListener('mousedown', () => {
            if (this.mouseIn) {
                this.isGrabbing = true
                document.body.style.cursor = 'grabbing'
            }
        })
        document.body.addEventListener('mouseup', () => {
            if (this.isGrabbing) this.isGrabbing = false
            if (!this.mouseIn)  document.body.style.cursor = 'default'
        })
        document.body.addEventListener('mousemove', (evt) => {
            if (this.isGrabbing) {
                let halfSpace = (window.innerHeight - 400) * 0.5
                let scroll = map(evt.clientY, halfSpace, window.innerHeight - halfSpace, 0, window.innerHeight * 4, true);
                window.scrollTo(0, scroll)
            }
        })
    }

    initBox() {
        this.box = this.container.querySelector('.box')
        this.moon = this.container.querySelector('.moon')
    }

    initPoints() {
        this.points = [...this.container.querySelectorAll('.point')]
    }

    init3d() {

    }

    initLines() {
        this.lineTop = this.container.querySelector('.line_top')
        this.lineBottom = this.container.querySelector('.line_bottom')
    }

    animateBox(index) {
        let current = index
        let old = this.current
        // do stuff
        this.rotate = current > old ? this.rotate +1 : this.rotate -1

        gsap.to(this.points[current], {
            scale: 0,
            duration: 0.4,
            ease: 'Cubic.easeOut'
        });

        if (old !== -1) {
            gsap.to(this.points[old], {
                scale: 1,
                duration: 0.7,
                ease: 'Cubic.easeOut'
            });
        }    

        gsap.to(this, {
            currentRotate: this.rotate,
            duration: 0.7,
            ease: 'Cubic.easeOut'
        });

        this.current = current 
    }

    scrollTo() {}

    click() {}

    update() {
        if (window.innerWidth > window.innerHeight) {
            let progress = map(ScrollManager.scroll * -1, 0, window.innerHeight * 4, 0, 1, true)
            if (this.progress !== progress) {
                let progressPX = progress * this.height
                let scaleTop = Math.max(progressPX - (this.boxHeight * 0.5), 0)
                let scaleBottom = Math.max(this.height - progressPX - (this.boxHeight * 0.5), 0)
        
                let progressFloor = Math.ceil(progress * 4 - 0.5)
        
                if (progressFloor !== this.progressFloor) {
                    this.animateBox(progressFloor)
                }
        
                let rotate = this.currentRotate % 1
                rotate = rotate < 0.5 ? rotate * 2.0 * 90 : (2.0 - (rotate * 2.0)) * 90
        
                // this.box.style.transform = `translate3d(0, ${progressPX}px, 0) rotate3d(0, 1, 0, ${rotate * dir}deg)`
                this.box.style.transform = `translate3d(0, ${progressPX}px, 0) rotate3d(0, 1, 0, ${rotate}deg)`
                this.lineTop.style.transform = `scaleY(${scaleTop})`
                this.lineBottom.style.transform = `scaleY(${scaleBottom})`
        
                this.progressFloor = progressFloor
                this.progress = progress
            }
        }
    }
}