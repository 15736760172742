<template>
  <div ref="preloader" id="preloader" class="absolute top-0 left-0 w-full h-screen flex m-auto bg-red z-50">
    <div class="m-auto">
      <div class="p-3 welcome-text text-white text-serif">
        bienvenue
      </div>
      <p class="loader pt-10 text-serif text-white">{{ Math.floor(loader.value) }}<sup>%</sup></p>
    </div>
  </div>
</template>

<script>
import { TweenMax, Power4 } from "gsap";
export default {
  name: "Preloader",
  components: {},
  props: {},
  data() {
    return {
      loader: { value: 0 },
      welcomeList: ["bienvenue", "bienvenida", "welcome", "어서 오십시오", "bem-vinda"],
    };
  },
  mounted() {
    var NewVal = 100;

    TweenMax.to(this.loader, {
      value: NewVal,
      roundProps: "val",
      duration: Math.max(3, Math.min(3, Math.random() * 10)),
      ease: Power4.easeInOut,
      onComplete: () => {
        this.onExit();
      },
    });
  },
  methods: {
    onExit: function() {
      TweenMax.to(this.$refs.preloader, {
        opacity: 0,
        filter: "blur(12px)",
        duration: 0.75,
        ease: Power4.easeInOut,
        onComplete: () => {
          this.$emit("loaded");
        },
      });
    },
  },
};
</script>
<style lang="scss" scope>
#preloader {
  z-index: 10000;
  &.bg-red {
    background-color: #bd0110;
  }

  .welcome-text {
    font-size: 80px;
  }
  .loader {
    font-size: 35px;
  }
}
</style>
