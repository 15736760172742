<template>
  <div id="hamburger" @mouseenter="onmouseenter" @mouseleave="onmouseleave" class="cursor-pointer p-5">
    <svg width="28" height="21" class="overflow-visible" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="line1" d="M0 3.68652L28 0V3.68652L0 7.37304V3.68652Z" fill="#fff" />
      <path class="line2" d="M0 10.1379L28 6.45141V10.1379L0 13.8245V10.1379Z" fill="#fff" />
      <path class="line3" d="M0 16.5893L28 12.9028V16.5893L0 20.2759V16.5893Z" fill="#fff" />
    </svg>
  </div>
</template>
<script>
import { gsap, Expo } from "gsap";

export default {
  data() {
    return {
      tl: null,
    };
  },
  mounted() {
    this.tl = gsap.timeline({ paused: true });
    this.tl
      .to("#hamburger", { rotate: "+=8", duration: .45, ease: Expo.out })
      .to("#hamburger .line1", { y: "-=1", x: "-=10", duration: .45, ease: Expo.out },0)
      // .to("#hamburger .line2", { scaleX: "+=150%", duration: 0.37 }, 0)
      .to("#hamburger .line3", { y: "+=1", x: "+=10", duration: .45, ease: Expo.out }, 0);
  },
  methods: {
    onmouseenter: function(el) {
      this.tl.play();
    },
    onmouseleave: function(el) {
      this.tl.reverse();
    },
    toggle: function(el) {},
  },
};
</script>
<style lang="scss" scoped></style>
