<template>
  <div class="video-container absolute flex top-0 left-0 w-full h-full overflow-hidden">
    <video
      id="mainVideo"
      :src="currentVideo"
      class="m-auto inline-flex object-cover min-h-screen min-w-full pointer-events-none user-select-none"
      autoplay
      playsinline
      loop
      muted
    ></video>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    videoData: {
      type: Object,
      required:true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      video: null,
      mobileFlag: false,
      currentVideo: "",
    };
  },
  methods: {
    updateVideo: function() {
      var mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches && !this.mobileFlag) {
        this.mobileFlag = true;
        this.currentVideo = this.videoData.landscape;
      } else if (!mediaQuery.matches && this.mobileFlag) {
        this.mobileFlag = false;
        this.currentVideo = this.videoData.portrait;
      }
      this.$forceUpdate();
    },
  },
  created() {
    window.addEventListener("resize", this.updateVideo);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateVideo);
  },
  mounted() {
    this.currentVideo = this.videoData.landscape;
    this.updateVideo();

    this.video = document.getElementById("mainVideo");
    this.video.addEventListener(
      "canplaythrough",
      function() {
        this.video.muted = true;
        this.video.play();
      }.bind(this)
    );
  },
};
</script>
<style lang="scss" scoped></style>
