<template>
  <header class="header fixed top-0 left-0 z-10 w-full pointer-events-none">
    <a @click.prevent="openTray" class="hamburger absolute md:top-8 top-2 md:left-6 left-2 pointer-events-auto">
      <Hamburger></Hamburger>
    </a>
    <!-- <div class="container relative"> -->
    <div ref="navscroller" class="navscroller breadcrumb absolute md:block hidden top-0 right-28 pointer-events-auto">
      <div class="line_top"></div>
      <div class="line_bottom"></div>
      <div class="point point-0" v-on:click="scrollToSection($event, 0)">
        <div class="point_bg"></div>
        <div class="point_circle"></div>
      </div>
      <div class="point point-1" v-on:click="scrollToSection($event, 1)">
        <div class="point_bg"></div>
        <div class="point_circle"></div>
      </div>
      <div class="point point-2" v-on:click="scrollToSection($event, 2)">
        <div class="point_bg"></div>
        <div class="point_circle"></div>
      </div>
      <div class="point point-3" v-on:click="scrollToSection($event, 3)">
        <div class="point_bg"></div>
        <div class="point_circle"></div>
      </div>
      <div class="point point-4" v-on:click="scrollToSection($event, 4)">
        <div class="point_bg"></div>
        <div class="point_circle"></div>
      </div>
      <div class="box">
        <div class="box_bg">
          <svg width="63" height="72" viewBox="0 0 63 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.822998 18.2887L31.4999 0.57735L62.1768 18.2887V53.7113L31.4999 71.4226L0.822998 53.7113V18.2887Z"
              stroke="white"
            />
          </svg>
        </div>
        <div class="moon">
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.86492 10.165C4.86525 8.84408 5.15669 7.53952 5.71841 6.34459C6.28014 5.14966 7.09827 4.09388 8.11433 3.25271C9.13039 2.41154 10.3193 1.80575 11.5961 1.47864C12.8728 1.15154 14.2059 1.1112 15.5 1.36051C14.0204 0.496022 12.3437 0.0278996 10.6313 0.00120901C8.91891 -0.0254816 7.2285 0.390158 5.7228 1.20811C4.2171 2.02607 2.94683 3.2188 2.03426 4.67147C1.1217 6.12414 0.597579 7.78784 0.51236 9.5024C0.427141 11.217 0.783694 12.9246 1.54769 14.461C2.31169 15.9974 3.4574 17.3108 4.87454 18.2747C6.29167 19.2387 7.9325 19.8207 9.63906 19.9647C11.3456 20.1088 13.0604 19.81 14.6184 19.0972C14.3521 19.1218 14.0843 19.1341 13.8149 19.1341C12.6396 19.1348 11.4756 18.9034 10.3895 18.4529C9.30349 18.0025 8.31665 17.3419 7.48545 16.5089C6.65424 15.6759 5.99497 14.6869 5.54532 13.5984C5.09568 12.5099 4.86447 11.3432 4.86492 10.165Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
    <div id="menuTray" class="absolute top-0 left-0 w-full min-h-screen hidden z-50 pointer-events-auto">
      <div class="bg absolute top-0 left-0 w-full h-full"></div>
      <div class="nav flex flex-col relative left-16 min-h-screen md:pt-40 md:pb-20 py-12 md:pl-48 pl-8">
        <div class="flex md:pl-40 w-full">
          <ul>
            <li v-for="(item, idx) in sectionData.menulist" :key="idx" itemprop="name" class="relative text-left pb-14">
              <router-link
                :to="item.url"
                itemprop="url"
                :title="`${item.line1} ${item.line2}`"
                tabindex="0"
                class="text-serif py-10"
                >{{ item.line1 }}<br /><span class="inline-block">{{ item.line2 }}</span></router-link
              >
            </li>
          </ul>
        </div>
        <img :src="home.titleimg" :alt="home.title" class="select-none hidden md:block" />
      </div>
      <ClosePanel :clickAction="closeTray"></ClosePanel>
    </div>
    <AudioToggle ref="audioToggle" class="absolute bottom-0 left-0 md:block hidden"></AudioToggle>
  </header>
</template>

<script>
import { gsap } from "gsap";
// import bodymovin from "@/plugins/lottie_svg.min";
import ClosePanel from "@/components/ClosePanel.vue";
import AudioToggle from "@/components/AudioToggle.vue";
import Hamburger from "@/components/Hamburger.vue";
import NavPoint from "@/components/NavPoint.vue";
import NavMarkerCurrent from "@/components/NavMarkerCurrent.vue";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import NavScroller from "@/animation/NavScroller.js";

export default {
  components: { AudioToggle, ClosePanel, Hamburger, NavPoint, NavMarkerCurrent },
  data() {
    return {
      navBreadcrumbs: [],
      tl: null,
      sectionData: window.locale.menu,
      home: window.locale["home"],
    };
  },
  mounted: function() {
    var navscroller = new NavScroller(this.$refs.navscroller);
    this.tl = gsap.timeline({ paused: true, onReverseComplete: this.onMenuClose });
    this.tl
      .from(".bg", {
        width: 0,
        duration: 0.35,
        delay: 0.0,
        ease: "cubic-bezier(.55,.52,.35,1)",
      })
      .from(".nav li", {
        opacity: 0,
        filter: "blur(4px)",
        y: "25%",
        duration: 0.35,
        delay: 0.2,
        stagger: 0.08,
        ease: "cubic-bezier(.55,.52,.35,1)",
      });
  },

  methods: {
    openTray: function(el) {
      let menuTray = document.querySelector("#menuTray");
      document.body.classList.add("menu-open");
      menuTray.classList.remove("hidden");
      this.tl.timeScale(1.0).play();
    },
    closeTray: function(el) {
      this.tl.timeScale(2.0).reverse();
    },
    onMenuClose: function() {
      document.body.classList.remove("menu-open");
      let menuTray = document.querySelector("#menuTray");
      if (menuTray) menuTray.classList.add("hidden");
    },
    scrollToSection: function(evt, id) {
      var dest = "/";
      switch (id) {
        case 0:
          dest = "/";
          break;
        case 1:
          dest = "about";
          break;
        case 2:
          dest = "hightown";
          break;
        case 3:
          dest = "buccaneer-bay";
          break;
        case 4:
          dest = "lowtown";
          break;
      }

      const path = window.location.pathname.substring(1)
      console.log(path, dest)
      if (path === dest) {
        ScrollManager.scrollTo(null, id);
      } else {
        this.$router.push(dest);
      }
    },
  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (to === from) return;
        this.closeTray();
        // const current = from.path.split("/")[1] == "" ? "home" : from.path.split("/")[1];
        const destination = to.path.split("/")[1] == "" ? "home" : to.path.split("/")[1];
        // var outMark = 0;
        var inMark = 0;
        switch (destination) {
          case "home":
            inMark = 0;
            break;
          case "about":
            inMark = 1;
            break;
          case "hightown":
            inMark = 2;
            break;
          case "buccaneer-bay":
            inMark = 3;
            break;
          case "lowtown":
            inMark = 4;
            break;
        }

        //trigger section scroll

        ScrollManager.scrollTo(null, inMark);
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
<style lang="scss">

#audioBtn {
  transition: transform 250ms ease-in-out;
}


header {
  height: calc(var(--vh) * 100);
  .menu-open & {
    .hamburger {
      display: none;
    }

    @media (max-width: 768px) {
      #audioBtn {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  #breadcrumbBG {
    // background: transparent url("/images/five.png") center center no-repeat;
    width: 73px;
    height: 391px;
    div {
      transform-origin: top center;
      transform: scale(0.9);
      top: -55px;
    }
    .bcbg4 {
      top: -59px;
    }
  }
  .navscroller {
    top: 50%;
    transform: translateY(-50%);
    height: 400px;

    @media (max-width: 1024px) {
      display: none;
    }

    .line_top,
    .line_bottom {
      position: absolute;
      left: -0.5px;
      width: 1px;
      height: 1px;
      background-color: #fff;
      will-change: transform;
    }

    .line_top {
      top: 0;
      transform-origin: top center;
    }
    .line_bottom {
      bottom: 0;
      transform-origin: bottom center;
      transform: scaleY(364);
    }
    .point {
      position: absolute;
      will-change: transform;
      width: 15px;
      height: 15px;
      left: -7.5px;
      cursor: pointer;

      &:hover {
        .point_bg {
          transform: scale(1.4);
        }
        .point_circle {
          transform: scale(1.6);
        }
      }

      &-0 {
        top: -7.5px;
      }
      &-1 {
        top: 92.5px;
      }
      &-2 {
        top: 192.5px;
      }
      &-3 {
        top: 292.5px;
      }
      &-4 {
        top: 392.5px;
      }

      &_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 0.5px solid white;
        transform: scale(1);
        transition: transform 500ms cubic-bezier(0.65, 0, 0.35, 1);
      }

      &_circle {
        position: absolute;
        will-change: transform;
        width: 5px;
        height: 5px;
        left: 5px;
        top: 5px;
        background-color: #fff;
        border-radius: 50%;
        transition: transform 300ms cubic-bezier(0.65, 0, 0.35, 1);
      }
    }

    .box {
      position: absolute;
      width: 63px;
      height: 72px;
      left: -31.5px;
      top: -36px;

      perspective: 1px;
      transform-style: preserve-3d;
      transform: rotate3d(0, 1, 0, 0deg);

      .moon {
        position: absolute;
        width: 15px;
        height: 20px;
        left: 25px;
        top: 25px;

        perspective: 1px;
        transform-style: preserve-3d;
        transform: rotate3d(0, 1, 0, 0deg);
      }
    }
  }
}
#menuTray {
  .bg {
    background-color: rgba(12, 22, 57, 0.9);
  }
  .nav {
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));
    overflow-y: auto;
    height: calc(var(--vh) * 100);
    justify-content: space-between;

    @media (max-width: 1025px) {
      left: 20vw;
    }

    @media (min-width: 1025px) {
      left: 261px;
      width: calc(100vw - 261px);
    }

    img {
      width: auto;
      max-width: 200px;
    }

  }
  ul {
    li {
      a {
        // padding-left:102px;
        font-style: normal;
        font-weight: 300;
        line-height: 17px;
        color: #ffffff;
        font-size: 18px;

        span {
          font-size: 50px;
          line-height: 1em;
        }

        @media (min-width: 768px) {
          font-size: 18px;
          span {
            // line-height: 67.18px;
            // font-size: 3vw;
            line-height: 6vw;
            font-size: 6vw;
            @include font-responsive(6vw, 50px, 100px);
          }
        }

        &:before {
          content: " ";
          width: 93px;
          border-top: solid 1px rgba(196, 196, 196, 0.5);
          display: block;
          position: absolute;
          left: 0px;
          top: 0px;

          @media (min-width: 768px) {
            display: inline-block;
            position: absolute;
            left: -102px;
            top: 20px;
          }
        }

        &.router-link-exact-active.router-link-active {
          color: $rollovercolor;
        }
        @media screen and (hover: hover) {
          transition: all 0.45s ease-in-out;
          &:hover {
            color: $rollovercolor;
          }
        }
      }
    }
  }
}
</style>
