import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/hightown",
    name: "Hightown",
    component: () => import("../views/Hightown.vue"),
  },
  {
    path: "/hightown/access",
    name: "Hightown",
    props: { default: true, passwordActive: true },
    component: () => import("../views/Hightown.vue"),
  },
  {
    path: "/hightown/gallery",
    name: "Art Gallery",
    props: { default: true, galleryActive: true },
    component: () => import("../views/Hightown.vue"),
  },
  {
    path: "/buccaneer-bay",
    name: "Buccaneer Bay",
    component: () => import("../views/BuccaneerBay.vue"),
  },
  {
    path: "/buccaneer-bay/access",
    props: { default: true, passwordActive: true },
    name: "Buccaneer Bay",
    component: () => import("../views/BuccaneerBay.vue"),
  },
  {
    path: "/buccaneer-bay/bay-loader",
    props: { default: true, containerActive: true },
    name: "Buccaneer Bay",
    component: () => import("../views/BuccaneerBay.vue"),
  },
  {
    path: "/lowtown",
    name: "Lowtown",
    component: () => import("../views/Lowtown.vue"),
  },
  {
    path: "/lowtown/brass-monkey-saloon",
    name: "Lowtown",
    component: () => import("../views/Lowtown.vue"),
  },
  {
    path: "/lowtown/invite",
    name: "Lowtown",
    component: () => import("../views/Lowtown.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
