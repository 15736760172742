<template>
  <div class="saloon">
    <div ref="bg" class="absolute w-full h-full top-0 left-0 select-none">
      <img
        :src="sectionData.brassmonkey.image.landscape"
        alt=""
        class="img-desktop w-full h-screen object-cover lg:block hidden"
      />
      <img
        :src="sectionData.brassmonkey.image.portrait"
        alt=""
        class="img-mobile w-full h-screen object-cover lg:hidden block"
      />
    </div>
    <div class="relative w-full min-h-screen flex m-0 content-left items-end">
      <div class="content absolute md:top-0 bottom-0 left-0 m-0 text-left h-full">
        <h2
          class="hidden md:block md:text-6xl text-4xl text-serif md:relative absolute md:bottom-0 bottom-24 md:left-0 left-16"
          v-html="sectionData.title"
        ></h2>
        <div class="md:pt-48 pt-0 md:relative absolute md:top-0 top-4">
          <router-link to="/lowtown"><ArrowScrollDown class="transform rotate-90"></ArrowScrollDown></router-link>
        </div>
        <div class="hidden md:block location absolute bottom-10 md:left-28 left-16 text-serif">
          <h2 v-html="sectionData.location"></h2>
        </div>
      </div>

      <!-- desktop hotspots -->
      <div class="md:block hidden">
        <Hotspot :height="270" :left="'47vw'" :bottom="'23vh'">
          <img class="wanted" src="/images/eastereggs/Sam_Wanted.jpg" alt="" />
        </Hotspot>
        <Hotspot :left="'34vw'" :bottom="'1vh'" :width="189" >
          <img class="wanted" src="/images/eastereggs/Bucky_Wanted.jpg" alt="" />
        </Hotspot>
        <Hotspot :width="400" :height="200" :bottom="'35vh'" :left="'19vw'">
          <img class="wanted" src="/images/eastereggs/Brass-Monkey.jpg" alt="" />
        </Hotspot>
        <router-link class="invite-hotspot" to="/lowtown/invite"></router-link>
      </div>

      <!-- mobile hotspots -->
      <div class="md:hidden block">
        <Hotspot :height="250" :left="'60vw'" :bottom="'35vh'">
          <img class="wanted" src="/images/eastereggs/Sam_Wanted.jpg" alt="" />
        </Hotspot>
        <Hotspot :left="'31vw'" :bottom="'13vh'">
          <img class="wanted" src="/images/eastereggs/Bucky_Wanted.jpg" alt="" />
        </Hotspot>
        <Hotspot :width="189" :height="188" :bottom="'46vh'" :left="'-5vw'">
          <img class="wanted" src="/images/eastereggs/Brass-Monkey.jpg" alt="" />
        </Hotspot>
        <router-link class="invite-hotspot-mobile" to="/lowtown/invite"></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import Hotspot from "@/components/Hotspot.vue";
import ArrowScrollDown from "@/components/ArrowScrollDown.vue";

export default {
  components: { Hotspot, ArrowScrollDown },
  props: {
    sectionData: {
      type: Object,
      default: function() {},
    },
  },
  mounted() {
    /* */
  },
  methods: {
    onmouseover: (el) => {},
    onmouseleave: (el) => {},
  },
}
</script>
<style lang="scss" scoped>
.saloon {
  .content {
    padding: 18.22vh 7.5vw 1.67vh;

    @media (min-width: 768px) {
      padding: 18.22vh 7.5vw 1.67vh;
    }
  }
  .location {
    font-size: 30px;
  }

  .invite-hotspot, .invite-hotspot-mobile{
      position: absolute;
      width: 150px;
      height: 90px;
      display: block;
      bottom: 10vh;
      left: 52vw;
  
  }
  .invite-hotspot-mobile{
    width: 150px;
    bottom:22vh;
    left:68vw;
  }
}
</style>
