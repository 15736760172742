<template>
  <div class="hsmodal-overlay overflow-hidden z-40">
    <transition v-on:enter="enter" v-on:leave="leave" :css="false" appear>
      <div class="relative w-full min-h-screen flex m-0 content-center items-center">
        <ClosePanel class="close-panel" :clickAction="closeGallery"></ClosePanel>
        <div
          ref="content"
          class="content relative left-16 min-h-screen h-full flex overflow-x-hidden overflow-y-auto my-auto text-left md:pl-14 pl-4"
        >
          <div class="relative w-full m-auto flex content-center items-center scroller-content md:pr-14 pr-4">
            <div class="w-full m-auto flex-none"><slot></slot></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import gsap from "gsap";
import ClosePanel from "@/components/ClosePanel.vue";
export default {
  components: {
    ClosePanel,
  },
  props: {},
  data: () => {
    return {
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("close");
    },
    beforeEnter(el) {
      gsap.set(el, {
        opacity: 0.0,
      });
    },
    enter(el, done) {
      this.showModal = true;
      gsap.fromTo(
        el,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.45,
          delay: 0,
          ease: "cubic-bezier(.55,.52,.35,1)",
          onComplete: function() {
            done();
          },
        }
      );
      gsap.from(this.$refs.content, {
        opacity: 0,
        y: "25%",
        duration: 0.35,
        delay: 0.5,
        ease: "cubic-bezier(.55,.52,.35,1)",
      });
    },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.5,
        delay: 0.0,
        opacity: 0,
        onComplete: done,
      });
    },
    closeGallery: function() {
      this.$emit("close");
      this.showModal = false;
      // this.$router.push("/hightown/access");
    },
  },
  mounted: function() {
    document.addEventListener("keydown", (e) => {
      if (this.show && e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          document.body.classList.add("hsmodal-open");
        } else {
          document.body.classList.remove("hsmodal-open");
        }
      }
    },
  },
};
</script>
<style lang="scss" scope>
body.hsmodal-open {
  overflow: hidden;
  transition: opacity 0.3s ease-out, filter 0.3s ease-out;

  section {
    .mask-container {
      filter: blur(4px) brightness(65%);
    }
  }

}
.close-panel {
  background: rgba(0, 0, 0, 0.88);
}
.hsmodal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh) * 100);
  .content {
    background-color: #0f1426;
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));

    @media (min-width: 768px) {
      left: 261px;
      width: calc(100vw - 261px);
    }
    .wanted {
      width: auto;
      height: auto;
      min-height: auto;
      max-height: 740px;
    }
    .w-paper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 4rem 0 0 0 !important;
      }
    }
  }

  .scroller-content {
    overflow-y: scroll;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.hsmodal-content {
  background-color: #0f1426;
  //   left: calc(261 / 1440 * 100vw);
  //   width: calc(100vw - (261 / 1440 * 100vw));
  //   @media (min-width: 768px) {
  //     left: 261px;
  //     width: calc(100vw - 261px);
  //   }
}
</style>
