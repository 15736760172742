<template>
  <div ref="app" id="app" class="relative mx-auto my-0 w-full min-h-screen max-h-full overflow-x-hidden">
    <RotateDevice />
    <WebGLVue @clickButton="onLoaderClick"></WebGLVue>
    <div class="view">
      <div class="scroll-container" ref="content">
        <Home></Home>
        <About></About>
        <Gallery></Gallery>
        <Book></Book>
        <BrassMonkey></BrassMonkey>
      </div>
    </div>
    <div class="scroll-real-height" ref="realheight" />
    <Menu ref="mainMenu"></Menu>
    <!-- <Preloader v-show="showPreloader" @loaded="showPreloader = false"></Preloader> -->
  </div>
</template>
<script>
import Menu from "./components/Navigation.vue";
import WebGLVue from "./components/WebGLVue.vue";

import WebGL from "@/webgl/WebGL.js";

import Preloader from "@/components/Preloader.vue";
import RotateDevice from "@/components/RotateDevice.vue";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Gallery from "@/views/Hightown.vue";
import Book from "@/views/BuccaneerBay.vue";
import BrassMonkey from "@/views/Lowtown.vue";
import ScrollManager from "./webgl/utils/ScrollManager";

export default {
  components: {
    WebGLVue,
    Preloader,
    RotateDevice,
    Menu,
    Home,
    About,
    Gallery,
    Book,
    BrassMonkey,
  },
  data() {
    return {
      showPreloader: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      WebGL.startLoading();
      // this.siteData = this.$i18n.messages[this.$i18n.locale];
      // console.log(this.siteData);
      const app = this.$refs.app;
      const content = this.$refs.content;
      const realHeight = this.$refs.realheight;
      ScrollManager.setApp(app, content, realHeight);
      this.onResize()
      window.addEventListener('resize', this.onResize.bind(this))
      //   const scroll = new LocomotiveScroll({
      //     el: target,
      //     getSpeed: true,
      //     lerp: 0.4,
      //     // multiplier
      //     smooth: true
      //   })
      //   console.log(scroll)
      //   scroll.scrollTo(target);
      //   scroll.on('scroll', (data) => {
      //     console.log(data.scroll.y)
      //     // WebGL.toGo = -Math.round(data.scroll.y);
      //     // scroll.update();
      //   })
    });
  },

  methods: {
    onResize() {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    onLoaderClick() {
      // ScrollManager.setHeight({target: ScrollManager.app})
      this.$refs.mainMenu.$refs.audioToggle.playFirst()
      const videos = document.querySelectorAll('video')
      videos.forEach(video => video.play())
    }
  }
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/lsh6ypy.css");

#app {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  top: 0;
  left: 0;

  @media (min-width: 1025px) {
    position: relative;
    height: calc(var(--vh) * 100);
    overflow-y: scroll;
  }
}

.scroll-real-height {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 0;
}

.view {
  @media (min-width: 1025px) {
    position: sticky;
    height: calc(var(--vh) * 100);
    overflow: hidden;
    width: 100%;
    top: 0;
    z-index: 2;
  }
}

.page {
  h2,
  p,
  a {
    color: $textsecondary;
  }
}
.menu-open {
  .scroll-container section,
  .webgl {
    filter: blur(8px);
  }
  .webgl {
    // opacity:0.8;
    z-index: 40;
  }
}
</style>
