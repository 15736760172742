export default class ImageToData {
    constructor(parameters) {
        this.parameters = parameters
        this.pixelPercentage = 0
        this.pixelThickness = 0
    }
    
    init() {
        return new Promise((resolve, reject) => {
            Promise.all([this.getImage(this.parameters.url), this.loadSVG(this.parameters.url)]).then(() => {
                this.initCanvas()
                this.initPixelData()
                this.checkPixel()
                // this.logAllData()
                resolve()
            })
        })
    }

    getImage(url) {
        return new Promise((resolve, reject) => {
            this.img = new Image()
            this.img.onload = () => {
                resolve()
            }
            this.img.src = url
        })
    }

    loadSVG(url) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest()
            xhr.open("GET", url, true)
            xhr.send()
            xhr.onload = (e) => {
                this.container = document.createElement("div")
                this.container.innerHTML = xhr.responseText
                resolve()
            }
        })
    }

    initCanvas() {
        this.canvas = document.createElement('canvas')
        this.canvas.classList.add('image_data')
        this.ctx = this.canvas.getContext('2d')
        this.canvas.width = this.img.width
        this.canvas.height = this.img.height
        this.imgWidth = this.img.width
        this.imgHeight = this.img.height
        this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height)
    }

    initPixelData() {
        this.pixelData = this.ctx.getImageData(0, 0, this.img.width, this.img.height).data
        this.pixelLength = this.pixelData.length / 4
    }

    checkPixel() {
        let blackPixels = 0
        let actual_scope = 0
        let line_scope = 0
        let prev = true
        
        const actuals = []
        let positions = []
        let x = 0, y = 0

        for (let i = 0; i < this.pixelData.length; i += 4) {
            if (this.pixelData[i] > 200) {
                positions.push({
                    x: x - (this.img.width * .5), 
                    y: y - (this.img.height * .5)
                })
                
                blackPixels++
                if (prev === true) {
                    actual_scope++
                }
                prev = true
            } else {
                prev = false
                if (actual_scope > 0) {
                    actuals.push(actual_scope)
                }
                actual_scope = 0
            }

            x++
            if (x >= this.img.width) {
                x = 0
                y++
            }

            if (actual_scope > line_scope) {
                line_scope = actual_scope
            }
        }
        
        let counts = {}, max = 0, thickness
        for (let value in actuals) {
            counts[actuals[value]] = (counts[actuals[value]] || 0) + 1
            if (counts[actuals[value]] > max) {
                max = counts[actuals[value]]
                thickness = actuals[value]
            }
        }

        this.positions = positions
        this.pixelThickness = thickness
        this.pixelPercentage = blackPixels / this.pixelLength
    }

    logAllData() {
        console.log("PIXEL %: " + this.pixelPercentage * 100, " THICKNESS(px) : " + this.pixelThickness)
    }
}
