var EPSILON = Number.EPSILON;

function clamp(value, min, max) {
    return Math.min(max, Math.max(min, value));
}

function lerp(min, max, t) {
    return min * (1 - t) + max * t;
}

function map(value, minA, maxA, minB, maxB, clamped = false) {
    if (clamped) value = Math.min(maxA, Math.max(minA, value));
	return ((value - minA) / (maxA - minA)) * (maxB - minB) + minB;
}

function inverseLerp(min, max, t) {
    if (Math.abs(min - max) < EPSILON) return 0;
    else return (t - min) / (max - min);
}

function smoothstep(min, max, t) {
    var x = clamp(inverseLerp(min, max, t), 0, 1);
    return x * x * (3 - 2 * x);
}

function mod(a, b) {
    return ((a % b) + b) % b;
}

const Easings = {
	linear: t => t,
	easeInQuad: t => Math.pow(t, 2),
	easeOutQuad: t => 1 - Math.pow(1 - t, 2),
	easeInOutQuad: t => t < .5 ? Math.pow(t * 2, 2) / 2 : (1 - Math.pow(1 - (t * 2 - 1), 2)) / 2 + .5,
	easeInCubic: t => Math.pow(t, 3),
	easeOutCubic: t => 1 - Math.pow(1 - t, 3),
	easeInOutCubic: t => t < .5 ? Math.pow(t * 2, 3) / 2 : (1 - Math.pow(1 - (t * 2 - 1), 3)) / 2 + .5,
	easeInQuart: t => Math.pow(t, 4),
	easeOutQuart: t => 1 - Math.pow(1 - t, 4),
	easeInOutQuart: t => t < .5 ? Math.pow(t * 2, 4) / 2 : (1 - Math.pow(1 - (t * 2 - 1), 4)) / 2 + .5,
	easeInQuint: t => Math.pow(t, 5),
	easeOutQuint: t => 1 - Math.pow(1 - t, 5),
	easeInOutQuint: t => t < .5 ? Math.pow(t * 2, 5) / 2 : (1 - Math.pow(1 - (t * 2 - 1), 5)) / 2 + .5
}

module.exports = {
    lerp: lerp,
    clamp: clamp,
    map: map,
    mod: mod,
    smoothstep: smoothstep,
    Easings: Easings
};  