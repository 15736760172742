<template>
  <section id="access" class="relative m-0 p-0 w-full min-h-screen">
    <!-- <transition v-on:enter="enter" v-on:leave="leave" :css="false" appear> -->
    <div ref="bg" class="mask-container absolute w-full h-full top-0 left-0 select-none">
      <img
        :src="componentData.access.image.landscape"
        alt=""
        class="img-desktop w-full h-screen object-cover lg:block hidden"
      />
      <img
        :src="componentData.access.image.portrait"
        alt=""
        class="img-mobile w-full h-screen object-cover lg:hidden block"
      />
    </div>

    <div class="relative w-full min-h-screen flex m-0 content-left items-end">
      <div class="content absolute top-0 left-0 m-0 text-left h-full">
        <h2 class="text-6xl text-serif md:block hidden" v-html="componentData.title"></h2>
        <div class="md:pt-48 pt-0">
          <a @click.prevent="clickBack"><ArrowScrollDown class="transform rotate-90"></ArrowScrollDown></a>
        </div>
        <div class="location absolute bottom-10 left-16 text-serif md:block hidden">
          <h2 v-html="componentData.location"></h2>
        </div>
      </div>
    </div>
    <div ref="accesspanel" class="access-panel absolute top-0 right-0 h-full">
      <div class="bg absolute w-full h-full top-0 left-0"></div>
      <div class="access-panel-content relative w-full min-h-screen p-5 text-left flex align-items">
        <div class="lg:h-full relative access-panel-content-text above-background">
          <h1 class="pb-5" v-html="componentData.access.title"></h1>
          <p v-html="componentData.access.content"></p>
        </div>
        <div class="relative above-background">
          <div class="access-cta pt-4 bottom-10 text-left">
            <p
              v-if="componentData.access.inputprompt !== ''"
              v-html="componentData.access.inputprompt"
              class="pb-4 pr-3 text-left hidden md\:block"
            ></p>
            <div ref="accessform">
              <span class="input-border relative inline-block pt-1 pb-2 text-center">
                <form @submit.prevent="accessCheck">
                  <input
                    ref="accessinput"
                    type="text"
                    v-model="accessCode"
                    :placeholder="componentData.access.inputcta"
                  />
                </form>
              </span>
              <span class="relative top-4">
                <a @click.prevent="accessCheck"
                  ><ArrowScrollDown class="transform -rotate-90 scale-75"></ArrowScrollDown
                ></a>
              </span>
            </div>
          </div>
        </div>
        <div class="hidden md:block absolute access-panel-background w-full h-full top-0 left-0"></div>
        <div v-if="showMobileAccessPanel" class="md:hidden absolute access-panel-background w-full h-full top-0 left-0"></div>
      </div>
    </div>
    <!-- </transition> -->
  </section>
</template>

<script>
import { TimelineMax, Elastic, gsap } from "gsap";
import ArrowScrollDown from "@/components/ArrowScrollDown.vue";

export default {
  props: {
    componentData: {
      type: Object,
      default: function() {},
    },
    accessDestination: {
      type: String,
      default: "access",
    },
    passwordoptions: {
      type: Array,
      default: function() {
        return ["Sharon Carter", "sharoncarter", "SharonCarter"];
      },
    },
    showMobileAccessPanel: {
      type: Boolean,
      default: false,
    },
    backDestination: {
      type: String,
      default: "home",
    },
  },
  components: { ArrowScrollDown },
  data() {
    return {
      tl: null,
      accessCode: "",
      showModal: false,
    };
  },
  methods: {
    accessCheck: function() {
      var passCheck = false;

      this.passwordoptions.forEach((item, index) => {
        if (this.accessCode == item) passCheck = true;
      });
      if (passCheck) {
        console.log(this.accessDestination);
        this.$router.push(this.accessDestination);
      } else {
        this.tl.restart();
      }

      // if (this.accessCode == this.componentData.access.password) {
      //   console.log(this.accessDestination);
      //   this.$router.push(this.accessDestination);
      // } else {
      //   this.tl.restart();
      // }
    },
    clickBack: function() {
      this.showModal = false;
      this.$router.push(this.backDestination);
    },
    beforeEnter(el) {
      // gsap.set(el, {
      //   opacity: 0.0,
      // });
    },
    enter(el, done) {
      this.showModal = true;
    },
    leave(el, done) {
      this.showModal = false;
    },
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
        } else {
          document.body.classList.remove("sectionmodal-open");
        }
      }
    },
  },
  mounted() {
    gsap.set(this.$refs.accessform, { transformOrigin: "center center" });
    this.tl = new TimelineMax({ paused: true, yoyo: true, repeat: 0 }).to(this.$refs.accessform, {
      x: "+=30",
      duration: 0.25,
      ease: Elastic.easeOut,
    });

    if (window.innerWidth > 700) {
      this.$refs.accessinput.select();
    }
  },
};
</script>
<style lang="scss" scoped>
#access {
  .content {
    padding: 12.22vh 2.5vw 1.67vh;

    @media (min-width: 768px) {
      padding: 18.22vh 7.5vw 1.67vh;
    }
  }
  .location {
    font-size: 30px;
  }

  .access-panel {
    width: calc(100vw - 75px);

    @media (min-width: 768px) {
      width: 60vw;
    }

    @media (min-width: 1025px) {
      width: 41.875vw;
    }

    .access-panel-content {
      flex-wrap: wrap;
      position: relative;
      display:block;

      > div {
        width: 100%;
      }
      @media (max-width: 700px) {
        overflow: hidden;
        height: 100%;
        overflow-y: scroll;
      }
    }

    .above-background {
      z-index: 5;
    }
    .access-panel-background {
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(#0F1426, 0.4);
      box-shadow: inset 20px -20px 50px rgba(#000, 0.5);
      z-index: 2;
      -moz-backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }

    h1 {
      
      font-size: 49px;
      @media (min-width: 768px) {
        font-size: 64px;
      }

      line-height: 1em;
    }
    .bg {
      background: rgba(15, 20, 38, 0.4);
      mix-blend-mode: multiply;
    }
    // background: linear-gradient(180deg, rgba(15, 20, 38, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    // filter: blur(20px);
    .p-5 {
      padding: 12.89vh 5.2vw;

      @media (min-width: 768px) {
        padding: 15.89vh 9.3vw;
      }
    }
    .access-cta {
      @media (max-width: 700px) {
        position: relative;
        bottom: 0;
      }

      p {
        font-weight: 300;
        font-size: 15px;
        line-height: 140%;
        max-width: 253px;
      }
      .input-border {
        &:before,
        &:after {
          content: " ";
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 55.56px;
          height: 1.11px;
          background: #fff;
        }
        &:before {
          top: -1px;
        }
        &:after {
          bottom: 0px;
        }
      }
      .top-4 {
        top: 15px;
      }
      input {
        max-width: 192px;
        position: relative;
        background: transparent;
        border: 1px solid #fff;
        font-size: 18px;
        line-height: 30px;
        margin: auto;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
