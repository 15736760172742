// import vertexShader from "./../shaders/title.vs"
// import fragmentShader from "./../shaders/title.fs"
import ImageToData from './../utils/ImageToData'
import Mouse from './../utils/Mouse'
import WebGL from './../WebGL'
import { gsap } from "gsap";

import {
    Group,
    Points,
    BufferGeometry,
    BufferAttribute,
    RawShaderMaterial,
    PlaneBufferGeometry,
    Mesh,
    Raycaster,
    Vector2,
    Vector3,
    ShaderMaterial,
    Texture
} from 'three'

var vertexShader = `
precision highp float;

attribute vec3 position;
attribute vec3 direction;
attribute float scale;
attribute float offset;
attribute float random;
attribute float alpharandom;

uniform mat4 projectionMatrix;
uniform mat4 modelViewMatrix;
uniform float uTime;
uniform float uAlpha;
uniform float uAnimation;
uniform vec3 uMousePosition;
uniform vec2 uImgSize;

varying float vDist;
varying float vRandom;

varying float vMaxAlpha;


void main() {
	vec3 pos = position;
    pos.xy = pos.xy * uImgSize;

    // float dist = distance(uMousePosition, pos);
    float dist = distance(uMousePosition.xy, pos.xy);
    vDist = min(dist / 300.0, 1.0);
    vDist = pow(vDist, 0.5);

    float max = uImgSize.x * 0.1 * random;

    vRandom = random;
    vMaxAlpha = alpharandom;

    float enable = uAnimation;

    // float mouseDistance = sin(uTime * 2.0);
    // float mouseDistance = dist + 0.5;
    float mouseDistance = 1.0 - vDist;
    pos.x += sin(uTime + offset) * max * direction.x * mouseDistance * enable;
    pos.y += cos(uTime + offset) *  max * direction.y * mouseDistance * enable;

    // pos.x +=  (1. - vDist) * 0.1 * direction.x * mouseDistance;
    // pos.y +=  (1.0 - vDist) *  0.1 * direction.y * mouseDistance;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
	gl_PointSize = scale * 2.3;
	// gl_PointSize = 1.0;
}
`

var fragmentShader = `
precision highp float;

uniform vec3 u_color;
uniform float uAlpha;
uniform float uAnimation;


varying float vDist;
varying float vRandom;
varying float vMaxAlpha;

float parabola( float x, float k ){
    return pow( 4.0*x*(1.0-x), k );
}

void main() {
    float alpha = 1.0 - vDist;
    vec3 color = vec3(1.0);

    float aAnimation = parabola(uAnimation, 1.0);

    alpha = mix(alpha, 1.0, aAnimation);
    // alpha = vDist;

    vec2 pos = gl_PointCoord.xy;
    float circle = step(length( abs(pos)-0.5 ), 0.5);

	gl_FragColor = vec4(color, alpha * circle);
    // gl_FragColor.a *= uAlpha;
    gl_FragColor.a *= vMaxAlpha;
    gl_FragColor.a *= mix(pow(uAnimation, 0.5), uAlpha, uAnimation);
    gl_FragColor.rgb /= gl_FragColor.a;
}
`

var disolveVertex = `
precision highp float;

varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

var disolveFragment = `
precision highp float;

varying vec2 vUv;

uniform sampler2D uTexture;
uniform vec2 uMouseUV;
uniform float uRatio;
uniform float uTime;

uniform float uAlpha;
uniform float uAnimation;

float circle(vec2 pos, vec2 _st, float _radius){
    vec2 dist = _st-pos;
	return 1.-smoothstep(_radius-(_radius*0.01),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*4.0);
}

vec3 mod289(vec3 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }
  
  vec2 mod289(vec2 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }
  
  vec3 permute(vec3 x) {
    return mod289(((x*34.0)+1.0)*x);
  }
  
  float snoise(vec2 v)
    {
    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                       -0.577350269189626,  // -1.0 + 2.0 * C.x
                        0.024390243902439); // 1.0 / 41.0
  // First corner
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
  
  // Other corners
    vec2 i1;
    //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
    //i1.y = 1.0 - i1.x;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    // x0 = x0 - 0.0 + 0.0 * C.xx ;
    // x1 = x0 - i1 + 1.0 * C.xx ;
    // x2 = x0 - 1.0 + 2.0 * C.xx ;
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
  
  // Permutations
    i = mod289(i); // Avoid truncation effects in permutation
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
          + i.x + vec3(0.0, i1.x, 1.0 ));
  
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
  
  // Gradients: 41 points uniformly over a line, mapped onto a diamond.
  // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)
  
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
  
  // Normalise gradients implicitly by scaling m
  // Approximation of: m *= inversesqrt( a0*a0 + h*h );
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
  
  // Compute final noise value at P
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
}

#define PI 3.1415926535

void main() {
    float dist = distance(uMouseUV, vUv);

    vec2 uv = vUv;
    // vec2 uv = (vUv - vec2(0.5)) * 1.0 + vec2(0.5); 
    // float w = sin((uv.x + uv.y - uTime + sin(4.5 * uv.x + 10.5 * uv.y) * PI * .3) * PI * .6);
    
    // uv *= 1. + (.036 - .036 * w);


    vec4 color = texture2D(uTexture, uv);
    // vec4 color = texture2D(uTexture, vUv);
	gl_FragColor = color;
	gl_FragColor.a *= uAnimation;
    gl_FragColor.rgb /= gl_FragColor.a;

}
`

let INTERSECTED;

export default class TitleGL extends Group {
    constructor(params) {
        super()
        this.params = params
        this.sectionId = params.section
        this.align = this.params.align || 'center'
        this.countParticles = 4000 // TODO: Check performance based on device (detect-gpu?)
        this.easeRayCastPoint = new Vector2()
        this.easeRayCastUV = new Vector2()
        this.targetPoint = new Vector2()
        this.targetUV = new Vector2()
        this.effectAlpha = 0.0
        this.needShow = false;
        const imageToData = new ImageToData({ url: params.imgUrl })
        imageToData.init().then(() => {
            this.img = imageToData.img
            this.positions2D = imageToData.positions
            this.imgWidth = imageToData.imgWidth
            this.imgHeight = imageToData.imgHeight
            this.initImageTitle(params.imgUrl)

            this.createGeometry()
            this.createMaterial()
            
            this.particles = new Points(this.geometry, this.material)
            this.add(this.particles)
            
            this.setPosition()

            this.addEvents()

            if (this.needShow) this.show()
        })

        this.position.y = 3000
    }

    initImageTitle(url) {
        let geo = new PlaneBufferGeometry(1, 1)
        // TODO: Use a custom shader to disolve the image based on mouse
        let mat = new ShaderMaterial({
            // color: '0xff0000',
            fragmentShader: disolveFragment,
            vertexShader: disolveVertex,
            transparent: true,
            uniforms: {
                uTexture: { value: null },
                uMouseUV: { value: new Vector2() },
                uRatio: { value: 0 },
                uTime: { value: 0 },
                uAlpha: { value: 0 },
                uAnimation: { value: 0 },
            }
            // map: texture,
            // opacity: 1.0
        })
        this.imageTitle = new Mesh(geo, mat)

        this.add(this.imageTitle)

        const texture = new Texture(this.img)
        this.imageDimensions = [this.imgWidth, this.imgHeight]
        // let ratio = this.imageDimensions[1]/this.imageDimensions[0]
        // this.imageTitle.scale.x = this.params.width * window.innerWidth
        // this.imageTitle.scale.y = this.imageTitle.scale.x * ratio

        texture.needsUpdate = true
        // this.imageTitle.material.uniforms.uRatio.value = ratio;
        this.imageTitle.material.uniforms.uTexture.value = texture;
        this.imageTitle.material.needsUpdate = true;
    }

    createGeometry() {
        // console.log(this.positions2D)
        let i3 = 0

        const positions = new Float32Array(this.countParticles * 3)
        const directions = new Float32Array(this.countParticles * 3)
        const scales = new Float32Array(this.countParticles)
        const offsets = new Float32Array(this.countParticles)
        const randoms = new Float32Array(this.countParticles)
        const alpharandom = new Float32Array(this.countParticles)

        for (let i = 0; i < this.countParticles; i++) {
            let randomIndex = Math.floor(Math.random() * this.positions2D.length)
            // let max = this.imageDimensions[1] * 0.5; // TBD - Calculate with code

            directions[i3] = Math.round(Math.random()) * 2 - 1
            directions[i + 1] = Math.round(Math.random()) * 2 - 1
            directions[i + 2] = Math.round(Math.random()) * 2 - 1

            let x = this.positions2D[randomIndex].x / (this.imgWidth * 0.5)
            let y = this.positions2D[randomIndex].y / (this.imgHeight * 0.5)

            positions[i3 + 0] = x //* (this.imageTitle.scale.x * 0.5)
            positions[i3 + 1] = y * -1 //* (this.imageTitle.scale.y * 0.5)
            positions[i3 + 2] = 0
            // positions[i3 + 2] = Math.random() * 1.

            // positions[i3 + 0] = Math.random() * 2.0
            // positions[i3 + 1] = Math.random() * 2.0
            // positions[i3 + 2] = Math.random() * 2.0
            // // scales[i] = Math.random() * 1.
            scales[i] = 0.5 + Math.random() * 1.0
            offsets[i] = Math.random() * Math.PI
            randoms[i] = Math.random() * Math.round(Math.random()) * 2 - 1

            alpharandom[i] = 0.1 + Math.random() * 0.9
            
            // if (positions[i3 + 1] == 0) {
            // }

            i3 += 3

        }

        this.geometry = new BufferGeometry()
        this.geometry.setAttribute("position", new BufferAttribute(positions, 3))
        this.geometry.setAttribute("direction", new BufferAttribute(directions, 3))
        this.geometry.setAttribute("scale", new BufferAttribute(scales, 1))
        this.geometry.setAttribute("offset", new BufferAttribute(offsets, 1))
        this.geometry.setAttribute("random", new BufferAttribute(randoms, 1))
        this.geometry.setAttribute("alpharandom", new BufferAttribute(alpharandom, 1))
    }

    createMaterial() {
        this.material = new RawShaderMaterial({
            uniforms: {
                uTime: { value: 1.0 },
                uMousePosition: { value: new Vector3(0, 0, 0)},
                uAlpha: { value: 0 },
                uImgSize: { value: new Vector2() },
                uAnimation: { value: 0.0 }
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true,
            depthTest: false,
            depthWrite: false
        })
    }

    setPosition() {
       // TODO: PREPARE REPONSIVE RESIZE / MOBILE HERE

        let ratio = this.imageDimensions[1]/this.imageDimensions[0]
        this.imageTitle.scale.x = this.params.width * window.innerWidth
        this.imageTitle.scale.y = this.imageTitle.scale.x * ratio

        this.imageTitle.material.uniforms.uRatio.value = ratio;

        this.material.uniforms.uImgSize.value.set(this.imageTitle.scale.x * 0.5, this.imageTitle.scale.y * 0.5)

        if (this.align === 'left') {
            this.position.x = window.innerWidth * this.params.left + this.imageTitle.scale.x * 0.5
            // this.position.x = this.params.left + this.imageTitle.scale.x * 0.5
        } else {
            this.position.x = window.innerWidth * this.params.left
        }
        this.position.y = -window.innerHeight * this.params.top
    }


    update() {
        if (this.material && this.visible) {
            // console.log('rendering')
            
            this.material.uniforms.uTime.value += 0.01
            this.imageTitle.material.uniforms.uTime.value += 0.002

            this.raycaster.setFromCamera(Mouse.mouseVector, WebGL.camera);

            this.easeRayCastUV.x += (this.targetUV.x - this.easeRayCastUV.x) * 0.07;
            this.easeRayCastUV.y += (this.targetUV.y - this.easeRayCastUV.y) * 0.07; 

            this.easeRayCastPoint.x += (this.targetPoint.x - this.easeRayCastPoint.x) * 0.07;
            this.easeRayCastPoint.y += (this.targetPoint.y - this.easeRayCastPoint.y) * 0.07;

            // let intersects = this.raycaster.intersectObject(this.particles);
            let intersects = this.raycaster.intersectObject(this.imageTitle);
            // console.log(intersects)

            let alpha = 0.0;

            if (intersects.length > 0) {
                for (var i = 0; i < intersects.length; i++ ) {
                    // console.log(intersects[i].point)       
                    alpha = 1.0
                    this.targetPoint.copy(intersects[i].point)
                    // this.targetPoint.x += this.position.x *
                    // this.targetPoint.y += this.position.y * 0.5
                    
                    this.targetPoint.y -= WebGL.scroll;
                    this.targetUV.copy(intersects[i].uv)
                    
                    let x = (this.easeRayCastUV.x * 2 -1.0) * this.imageTitle.scale.x * 0.5
                    let y = (this.easeRayCastUV.y * 2 -1.0) * this.imageTitle.scale.y * 0.5
                    // console.log(this.easeRayCastUV)
                    this.targetPoint.x = x 
                    this.targetPoint.y = y 

                    // console.log(this.targetPoint)

                    this.particles.material.uniforms.uMousePosition.value.copy(this.easeRayCastPoint)
                    this.imageTitle.material.uniforms.uMouseUV.value.copy(this.easeRayCastUV)
                    // console.log(intersects[i].uv)
                    // console.log(this.position)
                }
            } else {
                // disable here
                alpha = 0.0
            }

            this.effectAlpha += (alpha - this.effectAlpha) * 0.04

            this.particles.material.uniforms.uAlpha.value = this.effectAlpha 
            this.imageTitle.material.uniforms.uAlpha.value = this.effectAlpha 
        }
    }

    addEvents() {
        this.raycaster = new Raycaster();
        this.mouse = new Vector2();

        window.addEventListener('resize', this.setPosition.bind(this))
    }

    show() {
        if (!this.imageTitle) {
            this.needShow = true;
            return;
        }
        this.needShow = false;


        gsap.fromTo(this.imageTitle.material.uniforms.uAnimation, 
            { 
                value: 0
            },
            {
                value: 1.0, 
                duration: 1.2,
                delay: 0.7,
                ease: "easeOutCubic",
        });
        gsap.fromTo(this.particles.material.uniforms.uAnimation, 
            { 
                value: 0
            },
            {
                value: 1.0, 
                duration: 2.0, 
                ease: "easeOutCubic",
        });
    }
    hide() {
        if (!this.imageTitle) {
            this.needShow = false;
            return;
        }
        this.imageTitle.material.uniforms.uAnimation.value = 0.0
        this.particles.material.uniforms.uAnimation.value = 0.0
    }

    enable() {
        this.visible = true;
    }

    disable() {
        this.visible = false;
    }
}