<template>
  <transition>
    <section id="gallery" data-section="2" ref="container" class="absolute top-0 left-0 m-0 p-0 w-full min-h-screen">
      <div class="relative w-full h-screen flex m-0 content-center items-center">
        <ClosePanel :clickAction="closeGallery"></ClosePanel>
        <div
          ref="content"
          class="content relative left-16 min-h-screen mx-0 my-auto text-left md:pl-24 pl-4 md:pr-0 pr-4 overflow-y-auto"
        >
          <div v-if="galleryActive" class="lg:flex block space-x-6">
            <div class="intro">
              <h1 class="text-6xl md:pt-32 pt-10 md:pb-40 pb-5" ref="h1" v-html="sectionData.title"></h1>
              <h2 class="location pb-2" ref="h2" v-html="sectionData.subtitle"></h2>
              <Divider ref="divider"></Divider>
              <p class="text-left" ref="text" v-html="sectionData.content"></p>
            </div>
            <div class="gallery-wrapper">
              <div class="lg:block hidden">
                <!-- <vue-horizontal responsive scroll class="horizontal relative flex space-x-32"> -->
                <div class="grabcarousel" ref="grabcarousel">
                  <!-- <div class="grabscarousel"> -->
                  <div class="grabslider">
                    <div
                      v-for="(item, idx) in sectionData.imagelist"
                      :key="idx"
                      itemprop="name"
                      class="grabitem"
                      ref="grabitem"
                    >
                      <div><img class="img" :src="item.source" width="auto" height="auto" alt="" /></div>
                      <div class="textcontainer">
                        <div class="text-white" v-html="item.detail1"></div>
                        <div class="font-extrabold text-white">
                          <span v-html="item.detail2"></span>
                          <span class="arrowcnt" v-if="item.biddetails !== undefined" v-on:click="makeBid(idx)">
                            <div class="arrowscale">
                              <ArrowDown class="transform -rotate-90"></ArrowDown>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grabbar" ref="grabbar"><div class="grabprogress"></div></div>
                </div>
                <!-- </div> -->
                <!-- </vue-horizontal> -->
              </div>
              <div class="lg:hidden block pt-10 pb-10">
                <article
                  v-for="(item, idx) in sectionData.imagelist"
                  :key="idx"
                  itemprop="name"
                  class="gallery-item flex-none"
                >
                  <div><img class="img" :src="item.source" width="auto" height="auto" alt="" /></div>
                  <div class="lg:flex justify-between pt-2 pb-5 leading-tight">
                    <div class="text-white leading-tight" v-html="item.detail1"></div>
                    <div class="flex font-extrabold text-white leading-tight">
                      <span v-html="item.detail2"></span>
                      <span class="arrow-container" v-if="item.biddetails !== undefined" v-on:click="makeBid(idx)">
                        <ArrowDown class="transform -rotate-90 scale-50"></ArrowDown>
                      </span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="GalleryBid" ref="gallerymodal">
        <div class="leftbar">
          <ClosePanel :clickAction="closeBid"></ClosePanel>
        </div>
        <div class="container">
          <div v-html="sectionData.bidpage1.title" class="title"></div>

          <div class="container_panel panel1" ref="panel1">
            <div v-html="sectionData.bidpage1.status" class="status"></div>
            <div class="artist text-sans">
              <strong>{{ bidItem.biddetails.artist }}</strong> {{ bidItem.biddetails.title }},{{
                bidItem.biddetails.year
              }}
              <br />{{ bidItem.biddetails.medium }} <br />{{ bidItem.biddetails.dimensions }}
            </div>
            <!-- <div class="bid  text-sans" v-html="sectionData.bidpage1.bidlabel1"></div>
            <div class="bid bid_current text-sans font-bold">87 {{ sectionData.bidpage1.denomination }}</div> -->

            <div class="img_mobile" v-bind:style="{ backgroundImage: 'url(' + bidItem.source + ')' }"></div>

            <div class="bid text-sans" v-html="sectionData.bidpage1.bidlabel2"></div>
            <div class="bid bid_new text-sans font-bold">
              <span ref="bidvalue" role="textbox" type="number" contenteditable>88</span>
              {{ sectionData.bidpage1.denomination }}
            </div>

            <div class="bidbutton" v-html="sectionData.bidpage1.bidbutton" v-on:click="showPanelTwo"></div>
          </div>

          <div class="container_panel panel2" ref="panel2">
            <div v-html="sectionData.bidpage2.status" class="status"></div>
            <div class="artist text-sans" v-html="sectionData.bidpage2.bidline1"></div>

            <div class="img_mobile" v-bind:style="{ backgroundImage: 'url(' + bidItem.source + ')' }"></div>
            <div class="bid  text-sans" v-html="sectionData.bidpage2.bidamountlabel"></div>
            <div class="bid bid_current text-sans font-bold" ref="bidconfirm">{{ sectionData.bidpage2.bidamount }}</div>

            <div class="bid  text-sans" v-html="sectionData.bidpage2.content"></div>
          </div>
          <div ref="picture" class="picture" v-bind:style="{ backgroundImage: 'url(' + bidItem.source + ')' }"></div>
          <div v-html="sectionData.bidpage2.disclaimer" class="info text-sans-italic" ref="info"></div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { gsap } from "gsap";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import ClosePanel from "@/components/ClosePanel.vue";
import Divider from "@/components/Divider.vue";
import BidDetails from "@/components/BidDetails.vue";
import ArrowDown from "@/components/ArrowScrollDown.vue";
import GrabCarousel from "@/animation/GrabCarousel";
import { isMobile } from "@/webgl/utils/isMobile";

export default {
  components: { ArrowDown, ClosePanel, Divider, BidDetails },
  props: {
    showGallery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      galleryActive: true,
      showBid: false,
      sectionData: window.locale.gallery,
      bidItem: window.locale.gallery.imagelist[1],
    };
  },
  methods: {
    closeGallery: function() {
      this.$router.push("/hightown/access");
    },
    makeBid: function() {
      this.$refs.gallerymodal.style.display = "block";
      gsap.fromTo(
        this.$refs.gallerymodal,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.7,
          ease: "Cubic.easeOut",
        }
      );

      gsap.fromTo(
        this.$refs.picture,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 2,
          delay: 0.7,
          ease: "Cubic.easeOut",
        }
      );

      if (!this.hasBid) {
        this.$refs.panel1.style.display = "flex";

        gsap.fromTo(
          this.$refs.panel1,
          {
            y: 20,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.7,
            delay: 0.5,
            ease: "Cubic.easeOut",
          }
        );
      }
      // this.$router.push("/hightown/access");
    },
    showPanelTwo: function() {
      this.hasBid = true;
      this.$refs.info.style.display = "block";
      this.$refs.bidconfirm.textContent = `${this.$refs.bidvalue.textContent} BTC`;
      gsap.to(this.$refs.panel1, {
        opacity: 0,
        duration: 0.4,
        ease: "Cubic.easeOut",
        onComplete: () => {
          this.$refs.panel1.style.display = "none";
          this.$refs.panel2.style.display = "flex";
        },
      });
      gsap.to(this.$refs.info, {
        opacity: 1,
        duration: 1,
        ease: "Cubic.easeOut",
      });
      gsap.to(this.$refs.panel2, {
        opacity: 1,
        duration: 0.7,
        delay: 0.4,
      });
    },
    closeBid: function() {
      gsap.to(this.$refs.gallerymodal, {
        opacity: 0,
        duration: 0.7,
        ease: "Cubic.easeOut",
        onComplete: () => {
          this.$refs.gallerymodal.style.display = "none";
        },
      });
    },
  },
  destroyed() {
    if (this.carousel) this.carousel.visible = false;
  },
  mounted() {
    this.hasBid = false;
    let value = 87;
    this.$refs.bidvalue.addEventListener("input", () => {
      let text = this.$refs.bidvalue.textContent;
      if (isNaN(text) || text.length > 5) {
        this.$refs.bidvalue.textContent = value;
      } else {
        value = text;
      }
    });
    if (!isMobile) {
      ScrollManager.addObserver(this.$refs.container);
      this.carousel = new GrabCarousel(this.$refs.grabcarousel);

      let imgs = [...this.$refs.grabcarousel.querySelectorAll(".img")];
      imgs.forEach((img) => {
        if (img.complete) {
          this.carousel.setSize();
        } else {
          img.addEventListener("load", () => {
            this.carousel.setSize();
          });
        }
      });
    }
  },
  watch: {
    showGallery: function(newVal, oldVal) {
      // if (newVal !== oldVal) {
      //   if (newVal) {
      //     document.body.classList.add("sectionmodal-open");
      //   } else {
      //     document.body.classList.remove("sectionmodal-open");
      //   }
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
#gallery {
  z-index: 140;
  @media (max-width: 1024px) {
    position: fixed !important;
  }

  .intro {
    @media (min-width: 1024px) {
      max-width: 30vw;
    }
  }

  .GalleryBid {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(var(--vh) * 100);
    width: 100vw;
    background-color: #fff;
    z-index: 2;
    display: none;
    opacity: 0;

    .title {
      position: absolute;
      top: 56px;
      left: 56px;
      font-family: $serif;
      font-size: calc(36 / 1440 * 100vw);

      @media (max-width: 768px) {
        left: initial;
        right: 25px;
        top: 25px;
        padding-top: 0;
        font-size: 20px;
      }
    }

    .info {
      opacity: 0;
      display: none;
      position: absolute;
      bottom: 56px;
      left: 56px;
      font-size: 10px;

      @media (max-width: 768px) {
        bottom: 25px;
        left: 25px;
      }
    }

    .status {
      font-family: $serif;
      font-size: calc(48 / 1440 * 100vw);
      margin-bottom: 50px;

      @media (max-width: 768px) {
        font-size: 35px;
        margin-bottom: 10px;
      }
    }

    .artist {
      margin-bottom: 25px;
    }

    .bid_current {
      margin-bottom: 20px;
      font-weight: 700;
    }

    .bid {
      max-width: 25vw;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    .bid_new {
      font-weight: 700;
      width: fit-content;
      border-bottom: 1px solid #0f1426;
    }

    .bidbutton {
      width: 191px;
      height: 42px;
      border: 1px solid #0f1426;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 90px;
      font-weight: 700;
      cursor: pointer;
      transition: all 500ms cubic-bezier(0.33, 1, 0.68, 1);
      will-change: opacity, transform;
      transform: scale(1);
      opacity: 1;
      &:hover {
        transform: scale(0.975);
        // opacity: 0.8;
        transition: all 400ms cubic-bezier(0.33, 1, 0.68, 1);
      }

      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }

    .img_mobile {
      @media (min-width: 768px) {
        display: none;
      }
      width: 100%;
      height: 40vh;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .picture {
      position: absolute;
      right: 56px;
      top: 50%;
      transform: translateY(-50%);
      width: calc(630 / 1440 * 100vw);
      height: calc(100% - 112px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;

      @media (max-width: 768px) {
        height: 40vh;
        width: 100%;
        right: 0;
        background-position: center center;
        // background-size: cover;
        display: none;
      }
    }

    .leftbar {
      position: absolute;
      background-color: #0f1426;
      z-index: 2;
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
      }
    }

    .container {
      position: relative;
      text-align: left;
      left: 20%;
      width: 80%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      font-size: 15px;
      line-height: 25px;
      // align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 75px 56px 56px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        // width: 100%;
        // left: 0;
        padding: 55px 25px 25px;
      }
    }

    .container_panel {
      display: flex;
      flex-direction: column;
      opacity: 0;
    }

    .panel1 {
    }
    .panel2 {
      display: none;
      opacity: 0;
    }
  }

  .grab {
    cursor: grab;
  }
  .grabbing {
    cursor: grabbing;
  }

  .grabcarousel {
    padding-bottom: 50px;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }

  .arrowcnt {
    position: relative;
    padding-top: 6px;
  }

  .arrow-container {
    transform: translateY(-6px);
  }

  .arrowscale {
    transform-origin: top center;
    transform: scale(0.5);
  }

  .grabslider {
    display: flex;
    flex-wrap: nowrap;
    // height: 60rem;
    will-change: transform;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    .grabitem {
      margin-right: 47px;
      span.text-red {
        color: #bd0110 !important;
      }
    }

    .textcontainer {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .text-white {
        position: relative;
        display: flex;
      }
    }

    img {
      height: 50vh;
      width: auto;
      max-width: unset;
      pointer-events: none;
    }

    .details {
      font-size: 14px;
    }

    .grabitem:nth-child(even) {
      img {
        margin-top: 4vh;
      }
    }

    .grabitem:nth-child(1) {
      img {
        height: 48vh;
      }
    }
    .grabitem:nth-child(3) {
      img {
        height: 55vh;
      }
    }
  }

  .grabbar {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 1px;
    bottom: 0;
    background-color: #fff;

    @media (max-width: 768px) {
      background: transparent;
    }
  }

  .grabprogress {
    position: absolute;
    will-change: transform;
    left: 0;
    height: 2px;
    background-color: #fff;
    width: 30%;
  }

  .content {
    background-color: #0f1426;
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));
    @media (min-width: 768px) {
      left: 261px;
      width: calc(100vw - 261px);
    }
    @media (max-width: 768px) {
      height: calc(var(--vh) * 100);
      overflow-y: scroll;
    }

    h1 {
      font-size: calc(118 / 1440 * 100vw);

      @media(max-height: 700px) {
        padding: 4rem 0 3rem 0;
      }
    }
  }
  .gallery-wrapper {
    width: 100%;
    height: calc(var(--vh) * 100);
    overflow: hidden;
    // display: flex;
    // align-items: center;

    @media (max-width: 768px) {
      // width: calc(720 / 1440 * 100vw);
      width: 100%;
      height: fit-content;
      margin-right: 0;
      margin-left: 0;
      overflow: hidden;
    }

    .v-hl-container {
      padding-bottom: 30px;

      scrollbar-width: thin;
      scrollbar-color: #fff;
      background: transparent;
    }
    .v-hl-container::-webkit-scrollbar {
      height: 16px;
      width: 16px;
      background: transparent;
    }

    .v-hl-container::-webkit-scrollbar-track {
      background: transparent;
      background: #fff;
    }

    .v-hl-container::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: white;
    }
    .v-hl-container {
      display: block;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    .v-hl-btn {
      display: none;
    }
    article {
      width: 100%;
      margin-right: 0px;
      @media (min-width: 768px) {
        width: 442px;
        margin-right: 24px;
      }

      img {
        width: 100%;
        height: auto;
        // }
        &:nth-child(even) {
          padding-top: 25px;
        }

        .details {
          font-size: 14px;
          line-height: 1;

          span.text-red {
            color: #bd0110 !important;
          }
        }
      }
    }
  }
}
</style>
