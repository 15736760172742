<template>
  <section id="book" ref="container" data-section="3" class="relative m-0 p-0 w-full min-h-screen">
    <div ref="maskcontainer" class="mask-container absolute w-full h-full top-0 left-0 select-none">
      <div
        v-bind:style="{ backgroundImage: 'url(' + sectionData.image.landscape + ')' }"
        ref="imgdesktop"
        alt=""
        class="img-desktop"
      >
        <VideoBackground :videoData="sectionData.video" class="cover-darken"></VideoBackground>
      </div>
    </div>
    <div class="relative w-full min-h-screen flex m-0 content-center items-center">
      <div ref="content" class="content max-w-2xl mx-0 my-auto text-left pl-32">
        <h1 class="relative md:mb-40 mb-20 md:pt-0 pt-12" ref="h1" data-section="3">
          <span v-html="sectionData.title"></span>
          <span class="cta inline">
            <router-link to="/buccaneer-bay/access" class="relative flex m-auto pointer-events-auto py-2">
              <ArrowDown class="transform -rotate-90 scale-75"></ArrowDown>
            </router-link>
          </span>
        </h1>
        <h2 class="location pb-2" ref="h2" v-html="sectionData.location"></h2>
        <Divider ref="divider"></Divider>
        <p class="max-w-2xl text-left md:pr-0 pr-12 pb-12" ref="text" v-html="sectionData.content"></p>
      </div>
    </div>
    <transition :name="'modalfade'">
    <SectionModal v-show="passwordActive && showModal" :showModal="passwordActive && showModal" @close="showModal = false">
      <Password
        :componentData="sectionData"
        :backDestination="'/buccaneer-bay'"
        :accessDestination="'/buccaneer-bay/bay-loader'"
        :passwordoptions="['Power Broker', 'powerbroker', 'PowerBroker']"
        class="absolute top-0 left-0 w-full min-h-screen overflow-y-auto"
      ></Password>
      <!-- desktop hotspots -->
      <div class="md:block hidden">
        <Hotspot :height="250" :left="'35vw'" :bottom="'70vh'" :width="150" >
          <div class="w-paper flex space-x-28">
            <img class="wide" src="/images/eastereggs/Powerbroker_Wallpaper_Wide.jpg" alt="" />
            <img class="tall" src="/images/eastereggs/Powerbroker_Wallpaper_Tall.jpg" alt="" />
          </div>
        </Hotspot>
        <Hotspot :height="100" :left="'37vw'" :bottom="'10vh'" :width="200" >
          <div class="w-paper flex space-x-28">
            <img class="wide" src="/images/eastereggs/FlagSmasher_Wallpaper_Wide.jpg" alt="" />
            <img class="tall" src="/images/eastereggs/FlagSmasher_Wallpaper_tall.jpg" alt="" />
          </div>
        </Hotspot>
      </div>

      <!-- mobile hotspots -->
      <div class="md:hidden block">
        <Hotspot :height="150" :left="'60vw'" :bottom="'65vh'" :width="200" >
          <div class="w-paper flex space-x-28">
            <img class="tall" src="/images/eastereggs/Powerbroker_Wallpaper_Tall.jpg" alt="" />
          </div>
        </Hotspot>
        <Hotspot :height="100" :left="'0vw'" :bottom="'26vh'" :width="125" >
          <div class="w-paper flex space-x-28">
            <img class="tall" src="/images/eastereggs/FlagSmasher_Wallpaper_tall.jpg" alt="" />
          </div>
        </Hotspot>
      </div>

    </SectionModal>
    </transition>
    <transition :name="'modalfade'">
    <ContainerExplorer
      v-if="containerActive"
      :componentData="sectionData"
      class="absolute top-0 left-0 w-full min-h-screen overflow-y-auto"
    ></ContainerExplorer>
    </transition>
  </section>
</template>

<script>
import Divider from "@/components/Divider.vue";
import ArrowDown from "@/components/ArrowScrollDown.vue";
import Hotspot from "@/components/Hotspot.vue";
import SectionModal from "@/components/SectionModal.vue";
import Password from "@/components/Password.vue";
import VideoBackground from "@/components/VideoBackground.vue";
import ContainerExplorer from "@/views/ContainerExplorer.vue";

import WebGL from "@/webgl/WebGL.js";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import SectionAnimator from "@/animation/SectionAnimator";

// import * as PIXI from "pixi.js";
export default {
  components: { ArrowDown, ContainerExplorer, Divider, Hotspot, Password, SectionModal, VideoBackground },
  data() {
    return {
      showModal: false,
      passwordActive: false,
      containerActive: false,
      sectionData: window.locale["buccaneer-bay"],
    };
  },
  methods: {},
  watch: {
    $route: {
      handler: function(to, from) {
        if (to === from) return;
        this.passwordActive = to.path == "/buccaneer-bay/access";
        this.containerActive = to.path == "/buccaneer-bay/bay-loader";
        this.showModal = this.passwordActive;
      },
      deep: true,
      immediate: false,
    },
  },

  mounted() {
    SectionAnimator.add(3, this.$refs.maskcontainer, this.$refs.imgdesktop, this.$refs.imgmobile);
    ScrollManager.addObserver(this.$refs.container);

    SectionAnimator.addAnimation(3, this.$refs.h1, {
      content: this.$refs.content,
      divider: this.$refs.divider.$el,
      text: this.$refs.text,
      h2: this.$refs.h2,
    });

    ScrollManager.addAnimationObserver(this.$refs.h1);

  },
  created() {},
};
</script>
<style lang="scss" scoped>
#book {
  z-index: 3;
  .content {
    h1 {
      opacity: 0; //temp
      img {
        width: calc(784 / 1440 * 100vw);
        max-width: 784px;
      }
    }
  }
}
</style>
