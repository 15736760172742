<template>
  <div class="RotateDevice fixed flex items-center justify-center w-full h-full top-0 left-0" :class="{ show }">
    <div>
      <img src="@/assets/images/rotate-device.svg" class="RotateDevice__image mt-4 mb-8 relative w-full h-auto" alt="">
      <h1 class="RotateDevice__title" v-html="rotateDeviceMsg" />
    </div>
  </div>
</template>
<script>
import ScrollManager from '@/webgl/utils/ScrollManager'
import { isMobile } from '@/webgl/utils/isMobile'

export default {
  name: 'RotateDevice',
  data() {
    return {
      rotateDeviceMsg:  window.locale["rotateDeviceMsg"],
      show: false
    }
  },
  mounted() {
    console.log(navigator.userAgent)
    this.onResize()
    window.addEventListener('resize', () => this.onResize())
  },
  methods: {
    onResize() {
      const width = ScrollManager.width
      const height = ScrollManager.height

      if (isMobile && width < 800) {
        this.show = width > height && height < .75 * width
      } else if (isMobile) {
        this.show = width > height
      } else {
        this.show = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .RotateDevice {
    background-color: #0f1426;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;

    &.show {
      opacity: 1;
      pointer-events: auto;
      z-index: 1000;
    }

    &__title {
      font-size: 30px;
    }

    &__image {
      max-height: 100px;
    }
  }
</style>