import ScrollManager from '@/webgl/utils/ScrollManager'
import { map, Easings } from '@/webgl/utils/Math'
import { gsap } from 'gsap'

class SectionAnimator {
    constructor() {
        // this.sectionId = params.id
        this.toAnimate = []
        this.domAnimate = []
        
        window.addEventListener('resize', this.resize.bind(this))
    }

    add(id, container, imgDesktop, imgMobile) {
        let animate = {
            el: container,
            imgDesktop,
            imgMobile,
            id,
            transform: 1.0,
            enable: false,
            height: container.getBoundingClientRect().height
        }

        this.toAnimate.push(animate)
    }

    resize() {
        for(let i = 0; i < this.toAnimate.length; i++) {
          this.toAnimate[i].height = this.toAnimate[i].el.offsetHeight
        }
    }

    addAnimation(id, h1, content) {
        let animate = {
            id,
            container: content.content || null,
            text: content.text || null,
            h1: h1 || null,
            h2: content.h2 || null,
            divider: content.divider || null,
            extra: content.extra || null
        }
        this.domAnimate.push(animate)
        
        if (animate.text) animate.text.style.opacity = 0.0
        if (animate.h2) animate.h2.style.opacity = 0.0
        if (animate.divider) animate.divider.style.opacity = 0.0
        if (animate.extra) animate.extra.style.opacity = 0.0
    }

    update() {
        let top = 0
        let bottom = 0
        this.toAnimate.forEach(element => {
            if (!element.enable) return;

            bottom += element.height

            let deg = Math.min(6 * ScrollManager.delta * -1, 6.0);
            // let deg = 10.0
            // let scale = 1. + 0.2 * Math.abs(ScrollManager.delta) * 2;
            let scale = 1.2 + 0.4 * Math.abs(ScrollManager.delta);
            // scale = Math.round(scale * 100) / 100;
            if (element.transform  !== scale) {
                // ScrollManager
                element.el.style.transform = `rotate(${deg}deg)`

                let translate = map(ScrollManager.scroll * -1, top, bottom, 0,  element.height, false)
                let depth = map(ScrollManager.scroll * -1, (element.id - 1.0) * window.innerHeight, (element.id) * window.innerHeight, 1.4,  1.0, true)

                depth = Easings.easeInQuad(depth)
                // let depth = 1.0
                // console.log(element.id, ScrollManager.scroll)
                // element.imgDesktop.style.transform = `scale(${scale}) rotate(${-deg}deg)`
                if(element.imgDesktop) {
                  element.imgDesktop.style.transform = `rotate(${-deg}deg) translate3D(0, ${translate}px, 0) scale(${depth})`
                }
            }

            // console.log(ScrollManager.delta)
            element.transform  = scale;
            top += element.height
        });
    }

    animateInSection(id) {
        this.domAnimate.forEach((element) => {
            if (element.id === parseFloat(id)) {
                if (element.h1) {
                    gsap.fromTo(element.h1, {
                        opacity: 0,
                        y: 25
                    },  {
                        opacity: 1,
                        y: 0,
                        duration: 1.2,
                        delay: 0,
                        ease: 'easeOutCubic'
                    });
                }
                let delay = 0.4;
                if (element.h2) {
                    gsap.fromTo(element.h2, {
                        opacity: 0,
                        y: 20
                    },  {
                        opacity: 1,
                        y: 0,
                        duration: 1.0,
                        delay: delay,
                        ease: 'easeOutCubic'
                    });
                }
                if (element.divider) {
                    delay += 0.2;
                    gsap.fromTo(element.divider, {
                        opacity: 0,
                        y: 15,
                        // scale: 0.5
                    },  {
                        opacity: 1,
                        y: 0,
                        // scale: 1,
                        duration: 0.4,
                        delay: delay,
                        ease: 'easeOutCubic'
                    });
                }

                if (element.text) {
                    delay += 0.2;
                    gsap.fromTo(element.text, {
                        opacity: 0,
                        y: 10,
                    },  {
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        duration: 1.2,
                        delay: delay,
                        ease: 'easeOutCubic'
                    });
                }
                element.enable = true
                // gsap.fromTo(element.content, {
                //     opacity: 0,
                //     y: 25
                // },  {
                //     opacity: 1,
                //     y: 0,
                //     duration: 1.2,
                //     delay: 0.4,
                //     ease: 'easeOutCubic'
                // });
            }
        });
    }

    animateOutSection() {

    }



    enableSection(id) {
        this.toAnimate.forEach((element) => {
            if (element.id === parseFloat(id)) element.enable = true
        });
    }

    disableSection(id) {
        this.toAnimate.forEach((element) => {
            if (element.id === parseFloat(id)) element.enable = false
        });
    }
}

export default new SectionAnimator()