<template>
  <section id="hightown" data-section="2" ref="container" class="relative m-0 p-0 w-full min-h-screen">
    <!-- <div ref="maskcontainer" class="hidden sm:block mask-container absolute w-full h-full top-0 left-0 select-none">
      <div
        ref="imgdesktop"
        alt=""
        class="img-desktop"
      >
      <img :src="sectionData.image.landscape" alt="" class="w-full h-screen object-cover lg:block hidden" />
        <img :src="sectionData.image.portrait" alt="" class="w-full h-screen object-cover lg:hidden block" />
        <VideoBackground :videoData="sectionData.video" class="cover-darken"></VideoBackground>
      </div>
    </div> -->
    <div ref="maskcontainer" class="mask-container absolute w-full h-full top-0 left-0 select-none">
      <div
        v-bind:style="{ backgroundImage: 'url(' + sectionData.image.portrait + ')' }"
        ref="imgdesktop"
        alt=""
        class="img-mobile"
      >
        <VideoBackground :videoData="sectionData.video" class="cover-darken"></VideoBackground>
      </div>
    </div>
    <div class="hightown-content relative w-full min-h-screen flex m-0 content-center items-center">
      <div ref="content" class="content max-w-2xl mx-0 my-auto text-left pl-32">
        <h1 class="relative md:mb-40 mb-20 md:pt-0 pt-12" ref="h1" data-section="2">
          <span v-html="sectionData.title"></span>
          <span class="cta inline">
            <router-link to="/hightown/access" class="relative flex m-auto pointer-events-auto py-2">
              <ArrowDown class="transform -rotate-90 scale-75"></ArrowDown>
            </router-link>
          </span>
        </h1>
        <h2 class="location pb-2" ref="h2" v-html="sectionData.location"></h2>
        <Divider ref="divider"></Divider>
        <p class="text-left md:pr-0 pr-12 pb-12" ref="text" v-html="sectionData.content"></p>
      </div>
    </div>
      <transition :name="'modalfade'">
      <SectionModal v-show="passwordActive && showModal" :showModal="galleryActive || passwordActive && showModal" @close="showModal = false">
      <div>
        <Password
          :componentData="sectionData"
          :backDestination="'/hightown'"
          :accessDestination="'/hightown/gallery'"
          :showMobileAccessPanel="true"
          class="absolute top-0 left-0 w-full min-h-screen"
        ></Password>
      <!-- desktop hotspots -->
      <div class="md:block hidden">
        <Hotspot :width="200" :height="150" :left="'27vw'" :top="'0vh'">
          <img class="wanted" src="/images/eastereggs/Zemo_Wide_Wanted.jpg" alt="" />
        </Hotspot>
      </div>

      <!-- mobile hotspots -->
      <div class="md:hidden block">
        <Hotspot :width="200" :height="150" :left="'27vw'" :top="'0vh'">
          <img class="wanted" src="/images/eastereggs/Zemo_Wanted.jpg" alt="" />
        </Hotspot>
      </div>
      </div>
    </SectionModal>
      </transition>
    <transition :name="'gallery'">
      <Gallery v-if="galleryActive" :showGallery="galleryActive" class="absolute top-0 left-0 w-full min-h-screen"></Gallery>
    </transition>
  </section>
</template>

<script>
import Gallery from "@/views/Gallery.vue";
import ArrowDown from "@/components/ArrowScrollDown.vue";
import Hotspot from "@/components/Hotspot.vue";
import Password from "@/components/Password.vue";
import Divider from "@/components/Divider.vue";
import SectionModal from "@/components/SectionModal.vue";
import WebGL from "@/webgl/WebGL.js";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import VideoBackground from "@/components/VideoBackground.vue";
import SectionAnimator from "@/animation/SectionAnimator";

export default {
  components: { ArrowDown, Divider, Hotspot, Gallery, SectionModal, Password, VideoBackground },
  props: {},
  data() {
    return {
      showModal: false,
      passwordActive: false,
      galleryActive: false,
      sectionData: window.locale["hightown"],
    };
  },
  watch: {
    passwordActive: {
      handler: function(to, from) {},
      deep: true,
      immediate: true,
    },
    galleryActive: {
      handler: function(to, from) {},
      deep: true,
      immediate: true,
    },
    $route: {
      handler: function(to, from) {
        if (to === from) return;
        this.passwordActive = to.path == "/hightown/access";
        this.galleryActive = to.path == "/hightown/gallery";
        this.showModal = this.passwordActive;
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    /* */
  },
  mounted() {
    ScrollManager.addObserver(this.$refs.container);
    SectionAnimator.add(2, this.$refs.maskcontainer, this.$refs.imgdesktop, this.$refs.imgmobile);

    SectionAnimator.addAnimation(2, this.$refs.h1, {
      content: this.$refs.content,
      divider: this.$refs.divider.$el,
      text: this.$refs.text,
      h2: this.$refs.h2,
      h1: this.$refs.h1,
    });
    ScrollManager.addAnimationObserver(this.$refs.h1);
  },
  created() {},
};
</script>
<style lang="scss" scoped>
#hightown {
  .content {
    h1 {
      opacity: 0; //temp
      img {
        width: calc(784 / 1440 * 100vw);
        max-width: 784px;
      }
    }
  }
}
.sectionmodal-open {
  .hightown-content {
    filter: blur(8px);
    // opacity:0.4;
  }
}
</style>
