<template>
  <section id="about" ref="container" data-section="1" class="relative m-0 p-0 w-full min-h-screen">
    <div ref="maskcontainer" class="mask-container absolute w-full h-full top-0 left-0 select-none">
      <div
        v-bind:style="{ backgroundImage: 'url(' + sectionData.image.landscape + ')' }"
        ref="imgdesktop"
        alt=""
        class="img-desktop"
      >
        <VideoBackground :videoData="sectionData.video" class="cover-darken"></VideoBackground>
      </div>
      <!-- <img :src="sectionData.image.portrait" ref="imbmobile" alt="" class="img-mobile w-full h-screen object-cover lg:hidden block" /> -->
    </div>
    <div class="relative w-full min-h-screen flex m-0 content-center items-center">
      <div ref="content" class="content lg:max-w-2xl max-w-lg mx-0 my-auto text-left pl-32">
        <h1 class="relative md:mb-40 mb-20 md:pt-0 pt-12" ref="h1" data-section="1">
          <img
            :src="sectionData.titleimg"
            :alt="sectionData.title"
            width="auto"
            height="auto"
            class="select-none w-full m-0"
          />
        </h1>
        <h2 class="location pb-2" ref="h2" v-html="sectionData.location"></h2>
        <Divider ref="divider"></Divider>
        <p class="text-left md:pr-0 pr-12 pb-12" ref="text" v-html="sectionData.content"></p>
      </div>
    </div>
  </section>
</template>

<script>
import Divider from "@/components/Divider.vue";
import VideoBackground from "@/components/VideoBackground.vue";
import WebGL from "@/webgl/WebGL.js";
import ScrollManager from "@/webgl/utils/ScrollManager.js";
import VirtualAssistant from "@/components/VirtualAssistant.vue";
import SectionAnimator from "@/animation/SectionAnimator";
export default {
  components: { Divider, VideoBackground, VirtualAssistant },
  data() {
    return {
      sectionData: window.locale["about"],
    };
  },
  methods: {},
  mounted() {
    ScrollManager.addObserver(this.$refs.container);
    SectionAnimator.add(1, this.$refs.maskcontainer, this.$refs.imgdesktop, this.$refs.imgmobile);

    SectionAnimator.addAnimation(1, this.$refs.h1, {
      content: this.$refs.content,
      divider: this.$refs.divider.$el,
      text: this.$refs.text,
      h2: this.$refs.h2,
    });
    ScrollManager.addAnimationObserver(this.$refs.h1);

    // WebGL.addTitle({
    //   section: 1,
    //   imgUrl: this.sectionData.titleimg,
    //   top: 1.35,
    //   left: 0.12569444,
    //   align: "left",
    //   // width: 653
    //   width: 326 / 980,
    // });
  },
  created() {},
};
</script>
<style lang="scss" scoped>
#about {
  z-index: 2;
  .content {
    h1 {
      opacity: 0; //temp
      img {
        width: calc(526 / 1440 * 100vw);
        max-width: 526px;
        min-width: 240px;
        @media (min-width: 980px) {
          width: 526px;
        }
      }
    }
  }

  #travelAlert {
    width: 235px;
    background: rgba(189, 1, 16, 0.7);
    border-radius: 2px;
    color: #fff;

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 1em;
    }

    .cta {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
