<template>
  <div
    v-if="audioData.enabled"
    id="audioBtn"
    @click.prevent="toggle"
    class="pointer-events-auto cursor-pointer p-5 z-50"
  >
    <div class="relative w-full h-full">
      <span class="absolute top-0 left-0" v-if="muted">
        <svg width="86" height="100" viewBox="0 0 86 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M51.2226 41L34 57.9308L35.2903 59L52.5108 42.0692L51.2226 41Z" fill="white" />
          <path d="M44.8965 48.6124L44.519 48.1864V48.9851L44.8965 48.6124Z" fill="white" />
          <path d="M49.0255 44.5527L46.5127 41.7114V44.082L47.9035 45.656L49.0255 44.5527Z" fill="white" />
          <path
            d="M38.4246 54.9766L42.2687 51.196V48.8254V41.7476L37.1323 46.6954H34.2051V53.7306H37.1323L38.4246 54.9766Z"
            fill="white"
          />
          <path d="M46.8265 46.7168L45.7065 45.4495V47.3685L45.9178 47.6092L46.8265 46.7168Z" fill="white" />
          <path d="M39.6245 56.1332L42.2686 58.6784V53.5325L39.6245 56.1332Z" fill="white" />
          <path d="M46.0021 49.8627L44.519 51.3217V52.2162L46.2996 50.2013L46.0021 49.8627Z" fill="white" />
          <path
            d="M50.1312 45.803L49.0091 46.9063L51.9035 50.1779L46.5127 56.2738V58.6465L53.9999 50.1779L50.1312 45.803Z"
            fill="white"
          />
          <path
            d="M47.9301 47.967L47.0235 48.8595L48.2112 50.2013L45.7065 53.0341V54.9532L49.9076 50.2013L47.9301 47.967Z"
            fill="white"
          />
        </svg>
      </span>
      <svg width="86" height="100" viewBox="0 0 86 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          class="audioicon"
          ref="audioicon"
          d="M43 99.1L0.5 74.6V25.4L43 0.900024L85.5 25.5V74.6L85.2 74.8L43 99.1ZM1.8 73.8L43 97.5L84.2 73.7V26.2L43 2.50002L1.8 26.2V73.8Z"
          fill="transparent"
        />
        <g v-if="!muted">
          <path
            ref="icon"
            d="M36.7998 46.6H33.7998V53.4H36.7998L42.0998 58.2V51.4V48.7V41.9L36.7998 46.6Z"
            fill="white"
          />
          <path ref="p1" d="M46.4999 41.8V44.1L51.9999 50L46.3999 55.9V58.2L54.0999 50L46.4999 41.8Z" fill="white" />
          <path ref="p2" d="M45.5996 45.4V47.2L48.1996 49.9L45.5996 52.6V54.5L49.9996 50L45.5996 45.4Z" fill="white" />
          <path ref="p3" d="M44.3999 48.1V49.5L44.8999 50L44.3999 50.5V52L46.1999 50.1L44.3999 48.1Z" fill="white" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import { Howl, Howler } from "howler";
import { gsap } from "gsap";
import RAF from "@/webgl/utils/raf";

export default {
  data() {
    return {
      audioData: window.locale.audio,
      current: "home",
      first: true,
      muted: true,
      focused: true,
      sound: null,
      soundList: [],
      // soundList: ["home", "about", "hightown", "buccaneer-bay", "lowtown"],
    };
  },
  mounted() {
    this.audioData = window.locale.audio;
    this.waveFactor = 0;
    this.waves = [this.$refs.p1, this.$refs.p2, this.$refs.p3];
    this.time = 0;
    this.strokeLength = Math.round(this.$refs.audioicon.getTotalLength())

    RAF.add(this.loop)

    if (this.audioData.enabled) {
      const options = {
        src: this.audioData.src,
        preload: true,
        autoplay: false,
        muted: this.audioData.muted,
        loop: true,
        onplayerror: function() {
          this.sound.once("unlock", function() {
            if (!this.audioData.muted) {
              this.sound.play();
            }
          });
        },
      };
      this.sound = new Howl(options);

      //Howler.volume(0);
       Howler.volume(0.35);
      this.muted = this.audioData.muted;
      this.initFocusBlur();
    }
  },
  watch: {
    $route(to, from) {
      if (to === from) return;
      if (!this.muted && this.audioData.enabled) {
        const current = from.path.split("/")[1] == "" ? "home" : from.path.split("/")[1];
        const destination = to.path.split("/")[1] == "" ? "home" : to.path.split("/")[1];

        if (current == destination) return;
        this.current = destination;
      }
    },
  },

  methods: {
    enableWave() {
      gsap.to(this, {
        waveFactor: 1,
        duration: 0.4,
      });
    },
    disableWave() {
      gsap.to(this, {
        waveFactor: 0,
        duration: 0.7,
      });
    },
    loop() {
      this.time += 0.01;
      this.$refs.audioicon.style.strokeDashoffset = (Math.sin(this.time) * 0.5 + 0.5) * this.strokeLength 
      this.$refs.audioicon.style.strokeDasharray = this.strokeLength


      // console.log((this.strokeLength + this.time) % this.str)
      // this.circleProgress.style.strokeDashoffset = this.circleLength * 0.5 + this.time;
      // this.waves.forEach((wave, index) => {
      //   wave.style.opacity = (Math.sin(this.time + index * 0.2) * 0.5 + 0.5) * this.waveFactor
      // })
    },
    onmouseenter: function(el) {
      // this.tl.play();
    },
    onmouseleave: function(el) {
      // this.tl.reverse();
    },
    playAudio: function(aud) {
      if (typeof aud !== "undefined") {
        aud.play();
        this.enableWave();
      }
    },
    fadein: function(aud) {
      if (typeof aud !== "undefined") {
        if (!aud.playing()) aud.play();
        aud.fade(0, 1, 800);
      }
    },
    fadeout: function(aud) {
      if (typeof aud !== "undefined") {
        aud.fade(1, 0, 800);
      }
    },

    playFirst: function() {
        this.first = false;
        this.muted = false
        this.sound.play();
        this.enableWave();
    },

    toggle: function(el) {
      this.muted = !this.muted;
      // Howler.mute = this.muted;
      if (!this.muted) {
        if (this.first) {
          this.first = false;
          this.playAudio(this.sound);
        } else {
          this.fadein(this.sound);
          this.enableWave();
        }
      } else {
        this.fadeout(this.sound);
        this.disableWave();
      }
    },
    /**
     *
     * Focus / blur
     *
     */

    initFocusBlur() {
      document.addEventListener("visibilitychange", () => {
        document.hidden ? this.onExitLink() : this.handleWindowFocus();
      });
    },
    handleWindowFocus() {
      if (this.muted) return;
      this.fadein(this.sound);
    },
    onExitLink() {
      if (this.muted) return;
      this.fadeout(this.sound);
    },
  },
};
</script>
<style lang="scss" scoped>
#audioBtn {
  @media screen and (hover: hover) {
    svg {
      .audioicon {
        stroke: #fff;
        stroke-width: 0.5px;
        transition: opacity 0.7s cubic-bezier(0.33, 1, 0.68, 1);
        opacity: 1;
      }
    }
    &:hover {
      svg {
        .audioicon {
          transition: stroke 0.4s cubic-bezier(0.33, 1, 0.68, 1);
          opacity: 1;
          // fill: #fff;
        }
      }
    }
  }
}
</style>
