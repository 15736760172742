<template>
  <transition>
    <section id="invite" class="absolute top-0 left-0 m-0 p-0 w-full min-h-screen">
      <div class="relative w-full h-screen flex m-0 content-center items-center">
        <ClosePanel class="z-40" :clickAction="clickAction"></ClosePanel>
        <div
          ref="content"
          class="content z-30 relative left-16 min-h-screen mx-0 my-auto text-left md:pl-24 pl-4 md:pr-0 md:pr-24 pr-4 overflow-y-auto"
        >
          <div class="lg:flex block space-x-6">
            <div class="intro">
              <h1 class="text-6xl md:pt-32 pt-10 md:pb-40 pb-5" ref="h1" v-html="sectionData.title"></h1>
              <img
                :src="sectionData.artimage.portrait"
                alt=""
                class="w-full h-screen pb-4 object-cover lg:hidden block"
              />
              <h2 class="location pb-2" ref="h2" v-html="sectionData.subtitle"></h2>
              <Divider ref="divider"></Divider>
              <p class="text-left max-w-xl" ref="text" v-html="sectionData.content"></p>
            </div>
            <div class="wrapper relative top-0 max-w-lg md:pt-32 pt-10 md:pb-40 pb-5">
              <img :src="sectionData.artimage.landscape" alt="" class="w-full h-screen object-cover lg:block hidden" />
            </div>
          </div>
        </div>
      </div>
      <div ref="bg" class="z-20 background-image absolute w-full h-full top-0 left-0 select-none">
        <img
          :src="sectionData.image.landscape"
          alt=""
          class="img-desktop w-full h-screen object-cover lg:block hidden"
        />
        <img :src="sectionData.image.portrait" alt="" class="img-mobile w-full h-screen object-cover lg:hidden block" />
      </div>
    </section>
  </transition>
</template>

<script>
import Divider from "@/components/Divider.vue";
import { gsap } from "gsap";
import ClosePanel from "@/components/ClosePanel.vue";

export default {
  components: { ClosePanel, Divider },
  props: {
    accessDestination: {
      type: String,
      default: "access",
    },
    backDestination: {
      type: String,
      default: "home",
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessCode: "",
      inviteActive: false,
      sectionData: window.locale["lowtown"].brassmonkey,
    };
  },
  methods: {
    clickAction: function() {
      this.$router.push("/lowtown/brass-monkey-saloon");
    },
  },
  watch: {},
  created() {},
};
</script>
<style lang="scss" scoped>
#invite {
  .content {
    background-color: #0f1426;
    left: calc(261 / 1440 * 100vw);
    width: calc(100vw - (261 / 1440 * 100vw));

    @media (min-width: 768px) {
      left: 261px;
      width: calc(100vw - 261px);
    }
    @media (max-width: 768px) {
      height: calc(var(--vh) * 100);
      overflow-y: scroll;
    }

    h1 {
      font-size: calc(100 / 1440 * 100vw);
    }
    h2.location {
      font-size: 37px;
    }
  }

  .intro {
    width: 100%;
  }

  .wrapper {
    margin: 0 !important;
    right: 0;
    padding-left: 4rem;
  }

  img {
    height: auto;
  }

  .background-image {
    object-fit: cover;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
