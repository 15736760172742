<template>
  <a @click.prevent="clickAction" class="closebtn relative cursor-pointer inline-block">

    <svg v-if="theme === 'dark'" width="36" height="38" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="10" r="8.75" fill="" fill-opacity="0.8" stroke="#F28F38" stroke-width="0.5"/>
      <path d="M9.27656 9.616L11.7366 6.424H11.1846L9.00056 9.232H8.97656L6.79256 6.424H6.24056L8.71256 9.616L6.09656 13H6.64856L8.97656 9.988H9.00056L11.3166 13H11.8806L9.27656 9.616Z" fill="#F28F38"/>
    </svg>

    <svg v-else
      class="pointer-events-none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="34px"
      height="34px"
      overflow="visible"
    >
      <path
        d="M17,0C7.6,0,0,7.6,0,17s7.6,17,17,17c9.4,0,17-7.6,17-17S26.4,0,17,0z M28.7,26.3l-9.6-9.6l6.8-7.3l-1.5-1.4	l-6.7,7.2l-7.2-7.2L9.1,9.5l7.3,7.3l-6.8,7.3l1.5,1.4l6.7-7.2l9.6,9.6C24.7,30.4,21,32,17,32C8.7,32,2,25.3,2,17C2,8.7,8.7,2,17,2	s15,6.7,15,15C32,20.5,30.8,23.8,28.7,26.3z"
        fill="#f2ecde"
      />
    </svg>
  </a>
</template>
<script>
export default {
  props: {
    clickAction: {
      type: Function,
      default: function() {
        this.$emit("close");
      },
    },
    theme: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scope>
.closebtn {
  &:hover {
    svg {
      circle {
        fill: #333;
      }
    }
  }
}
</style>
