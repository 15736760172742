<template>
  <a class="arrow-down inline-block cursor-pointer" @mouseover="onmouseover" @mouseleave="onmouseleave">
    <svg
      class="m-auto pointer-events-none"
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref="arrow"
        d="M34.0911 18.9302H28.52L23 23.9954L17.48 18.9302H11.9089L23 29.0605L34.0911 18.9302Z"
        fill="white"
      />
      <path
        ref="circle"
        d="M1.12439 22C1.12439 9.92561 10.9377 0.102356 22.9999 0.102356C35.0622 0.102356 44.8755 9.92561 44.8755 22C44.8755 34.0745 35.0622 43.8977 22.9999 43.8977C10.9377 43.8977 1.12439 34.0745 1.12439 22ZM43.3422 22C43.3422 10.7954 34.1933 1.63724 22.9999 1.63724C11.8066 1.63724 2.65772 10.7954 2.65772 22C2.65772 33.2047 11.8066 42.3628 22.9999 42.3628C34.1933 42.3628 43.3422 33.2047 43.3422 22Z"
        fill="white"
      />
    </svg>
  </a>
</template>
<script>
import { gsap, Power4 } from "gsap";
export default {
  data() {
    return {
      tl: null,
    };
  },
  mounted() {
    this.tl = gsap.timeline({ paused: true });
    this.tl
      .to(this.$refs.circle, { transform: "scale(0.82,0.82)", duration: 0.5,force3d:true, transformOrigin:"center center", ease: Power4.easeOut })
      .to(this.$refs.arrow, { fill: "#00F0FF", duration: 0.5, ease: Power4.easeOut }, 0);
  },
  methods: {
    onmouseover: function(el) {
      this.tl.play();
    },
    onmouseleave: function(el) {
      this.tl.reverse();
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
